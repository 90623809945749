import "../../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/onboarding-page.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W246bMBB936+wVqq0efAKcmFTqkp97TdUVWXwAN6AjWyTS6v999rmEkNCs2n7kkTjmTMzZ255/hFuok2zagL06wGhhKS7XIqGU5yKUsgY7Yl8wphkGeOAvedasorIU6u2+GRsC2B5oWMUBsG+sALKVF2SU4yyEo5WYL8xZRJSzQSPkbFtKm5fxB5kVopDjApGKRjZ28PzENo3SjTBTOGKpEJhKA2AFPzzo5YNPH53kZtgcmYwt7VzlQhJQWJJKGvUIO1DTEmZPrk4EUZhVB8XI4ehQ6wJpYznWIs6Rst1i9ALZQs0iIVibU5Ks3R3sjJnF9xJBClZzjHTUKlWHQOn9gEfINkxjUldYwm5M6OS5OPAB6YOjFNxmOFqlFlwJS0ny4l5teSMXCx9BENAME9AIrQWVYf2953lOV8553fQ+dqYcDKLxTVwW3nzCfKC6bN4yGxrMjPp2c8wCiYsrLueOxqiqS5iFAVTlc0/x6pqkhpmQB8A+FCRVdQyXjGO+35eb6beI+c9M1A4IxUrTQRf+xSdVLGfMKmBkxc4XJyV9Kk0WlzIipSD9NB5NTlbWWlt+0hW0355uZeGywlQmkg96cgOwbSM2RnhyOP2fR6N5Xvc9RPePhWS8Z3raM/hR+dwpp88sluokaHa58746khI0g/L4loE/c7rh3Y7QSfnFpgrdkIULC7qugnG+SUOqGv0zXLaaunZz6Qz3uPbA6IejwrKbFwPTxHaHTSsHJIo0z4aRruWCw7tZmK2Fhj2piaql3tomXc/xnvdU8rvbWOveTyY4nZNNNPllaK8XBm29RSe3YYvcDS61qug2+A3R3s5He3XGU7+5P6oxv8VumF2bHW/u0Yz5/nD1bXoX8X/clV2syOo4WhSAeOaztnGe2baEOj9GF8qoIwglUqz3pGZdfTkHZQwsM2zcLCT++cdKXud7Mp118oS8uarr6fqa6vvKjs6Xj3Vbzejeom2F0ElnZdLsN8GQOOIXQoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var button = '_1565u3uc';
export var buttonWrapper = '_1565u3u8';
export var checkBox = '_1565u3u9';
export var content = '_1565u3u5';
export var disableButton = '_1565u3ue';
export var footer = '_1565u3u2';
export var header = '_1565u3u1';
export var input = '_1565u3ub';
export var label = '_1565u3ua';
export var layout = '_1565u3u0';
export var link = '_1565u3uk';
export var linkGroup = '_1565u3uj';
export var onboardingContainer = '_1565u3u4';
export var openAFFiNEButton = '_1565u3ud';
export var optionsWrapper = '_1565u3u7';
export var question = '_1565u3u6';
export var scrollableContainer = '_1565u3u3';
export var thankContainer = '_1565u3ug';
export var thankText = '_1565u3ui';
export var thankTitle = '_1565u3uh';
export var windowsAppButton = '_1565u3uf';