import "../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/components/page-display-menu.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE62T0U6DQBBF3/sVG5/swzSAbW1p/BJjzALDMkqXzbItVOO/u7vYCpVGm/hEMgzn3jszzJ7DdcLTggL2PmFsSxIaykwRsygKVLuZfExmx5awa+Fa2K4ScxOzcOl6GIMGk1cywJUCjYIqGTNZQaa5GCAij1A8y0gK0CQKC1md6dz5prySBmp6w5jtub4F4HlOEsHX23rqZPMSW2g0V07NPYecx4wbDjw1tMeHG6N3ePPk2WlVVvqMqzTZaAfw76YD0Nx/lFGtSn6IvapT5yUJCWRwW8csRWlQu/LLrjaUO5CtSJuvVjxFSNA0iNJ1CGd41U3Ox2mwm8QiCDZ/it4zt/ie1hEz7zBjKQ22Fo3WW3Yh7LK/IstSLQujzmvxxY/63n9b0Ui+K4yd7i2pjKm23s/A7v1/2u1xV+M7711cd2/OYHv6Z4Kgk+pveMRbT2d98SD9RKwBnpQ4tqj1VeftaT9u/BO09/tt/wMAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var currentGroupType = '_19bachi5';
export var headerDisplayButton = '_19bachi1';
export var listOption = '_19bachi6';
export var menu = '_19bachi0';
export var properties = '_19bachi7';
export var propertiesWrapper = '_19bachi8';
export var propertyButton = '_19bachi9';
export var subMenuItem = '_19bachi3';
export var subMenuTrigger = '_19bachi2';
export var subMenuTriggerContent = '_19bachi4';