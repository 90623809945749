import { AIStarIcon } from '@blocksuite/affine-components/icons';
import { type EditorHost } from '@blocksuite/affine/block-std';
import { WithDisposable } from '@blocksuite/affine/global/utils';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type {
  AIItemGroupConfig,
  CopilotSelectionController,
  FrameBlockModel,
} from '@blocksuite/blocks';
import type { EdgelessRootBlockComponent } from '@blocksuite/blocks';
// import type { EdgelessRootBlockComponent } from '../../edgeless/edgeless-root-block.js';
import { MOUSE_BUTTON } from '@blocksuite/affine-shared/utils';
import { requestConnectedFrame } from '@blocksuite/affine-shared/utils';
import { GfxGroupLikeElementModel as SurfaceGroupLikeModel } from '@blocksuite/block-std/gfx';
import { BlockModel } from '@blocksuite/store';
import {
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
  size,
} from '@floating-ui/dom';
// import { SurfaceGroupLikeModel } from '@blocksuite/blocks/dist/surface-block/element-model/base.js';
// import { isFrameBlock } from '@blocksuite/blocks/dist/root-block/edgeless/utils/query.js';
import { EdgelessCopilotPanel } from './custom-panel';
import { getEdgelessCopilotWidget } from '../../utils/edgeless';
import axios from 'axios';
import EventBus from '../../../../../eventBus';

export function isFrameBlock(
  element: BlockModel | BlockSuite.EdgelessModel | null
): element is FrameBlockModel {
  return (
    !!element && 'flavour' in element && element.flavour === 'affine:frame'
  );
}

@customElement('edgeless-copilot-toolbar-entry-custom')
export class EdgelessCopilotToolbarEntry extends WithDisposable(LitElement) {
  static override styles = css`
    .copilot-icon-button {
      line-height: 20px;

      .label.medium {
        color: var(--affine-brand-color);
      }
    }
  `;

  private _showCopilotPanel() {
    const selectedElements = this.edgeless.service.selection.selectedElements;
    const toBeSelected = new Set(selectedElements);
    selectedElements.forEach(element => {
      if (isFrameBlock(element)) {
        this.edgeless.service.frame
          .getElementsInFrame(element)
          .forEach(ele => toBeSelected.add(ele));
      } else if (element instanceof SurfaceGroupLikeModel) {
        element.descendants().forEach(ele => toBeSelected.add(ele));
      }
    });
    const edgelessCopilot = getEdgelessCopilotWidget(this.host);

    // this.edgeless.service.tool.setEdgelessTool({
    //   type: 'copilot-custom',
    // });
    let blockId = selectedElements?.[0]?.id;
    let pageId = selectedElements?.[0]?.doc?.id;

    requestConnectedFrame(async () => {
      const panel = new EdgelessCopilotPanel();
      panel.host = this.host;
      panel.groups = this.groups;
      panel.edgeless = this.edgeless;

      let blockId = selectedElements?.[0]?.id;
      let pageId = selectedElements?.[0]?.doc?.id;
      let response;
      try {
        response = await axios.get(
          `/back_api/database-services/get_container_detail?guid=${pageId}&container_id=${blockId}`
        );
      } catch (error) {
        response = {};
      }
      let originData;
      if (localStorage.getItem(blockId)) {
        originData = JSON.parse(localStorage.getItem(blockId) || '{}');
      } else {
        try {
          originData = JSON.parse(response?.data?.detail_content || '{}');
        } catch (error) {
          originData = {};
        }
      }
      // let originData = JSON.parse(localStorage.getItem(blockId) || '{}');

      let data = [];
      let type = '';
      if (originData.hasOwnProperty('note_cover')) {
        edgelessCopilot.hideCopilotPanel();
        this.edgeless.service.tool.setEdgelessTool({
          type: 'copilot-custom',
        });
        EventBus.emit('showDetail', blockId);
        return;
      } else if (originData.hasOwnProperty('cards')) {
        edgelessCopilot.hideCopilotPanel();
        this.edgeless.service.tool.setEdgelessTool({
          type: 'copilot-custom',
        });
        EventBus.emit('showCards', blockId);
        return;
        // data = [...originData.cards];
        // type = 'cards';
      } else if (originData?.notes?.length) {
        data = [...originData.notes];
        type = 'notes';
      } else if (originData?.docs?.length) {
        data = originData?.docs;
        type = 'docs';
      } else {
        edgelessCopilot.hideCopilotPanel();
        this.edgeless.service.tool.setEdgelessTool({
          type: 'copilot-custom',
        });
        EventBus.emit('showEdit', blockId);
        return;
      }
      panel.setData(data);
      panel.setType(type);
      panel.setSelectedCard(originData?.selected || []);
      panel.setContainerId(blockId);

      edgelessCopilot.renderRoot.append(panel);

      const referenceElement = this.host
        .getElementsByTagName('edgeless-selected-rect')[0]
        .shadowRoot?.querySelector('.affine-edgeless-selected-rect');

      const viewport = this.edgeless.service.viewport;

      if (!referenceElement || !referenceElement.isConnected) return;

      autoUpdate(referenceElement, panel, () => {
        computePosition(referenceElement, panel, {
          placement: 'right-start',
          middleware: [
            offset({
              mainAxis: 16,
            }),
            flip({
              mainAxis: true,
              crossAxis: true,
              flipAlignment: true,
            }),
            shift(() => {
              const { left, top, width, height } = viewport;
              return {
                padding: 20,
                crossAxis: true,
                rootBoundary: {
                  x: left,
                  y: top,
                  width,
                  height: height - 100,
                },
              };
            }),
            size({
              apply: ({ elements }) => {
                const { height } = viewport;
                elements.floating.style.maxHeight = `${height - 70}px`;
              },
            }),
          ],
        })
          .then(({ x, y }) => {
            panel.style.left = `${x}px`;
            panel.style.top = `${y}px`;
          })
          .catch(e => {
            console.warn("Can't compute EdgelessCopilotPanel position", e);
          })
          .finally(() => {
            edgelessCopilot.hideCopilotPanel();
          });
      });

      edgelessCopilot.block.dispatcher.add('pointerDown', ctx => {
        const e = ctx.get('pointerState').raw;

        if (
          e.button === MOUSE_BUTTON.MAIN &&
          !edgelessCopilot.contains(e.target as HTMLElement)
        ) {
          panel?.hide();
          edgelessCopilot.hideCopilotPanel();
        }
      });
    }, edgelessCopilot);
  }

  override render() {
    return html`
      <edgeless-tool-icon-button
        aria-label="Show Detail"
        class="copilot-icon-button"
        @click=${this._showCopilotPanel}
      >
        ${AIStarIcon} <span class="label medium">Show Detail</span>
      </edgeless-tool-icon-button>
    `;
  }

  @property({ attribute: false })
  accessor edgeless!: EdgelessRootBlockComponent;

  @property({ attribute: false })
  accessor groups!: AIItemGroupConfig[];

  @property({ attribute: false })
  accessor host!: EditorHost;
}
