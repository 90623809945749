import "../../core/src/components/affine/setting-modal/general-setting/plans/layout.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/plans/layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VV227bMAx971doDwOaYSqcNM3a9GW/MQzDQFtyrEWWDElO0g7991EX15c5TYc9JBFI6ZA85GG+7vlTaaDmlvx8YGXbupz8viKkNLoOB0JqMDuhaK6d0/WW0GWWNadHdL3gx+n5W1m88HJ1k2CzcI8J20h42pJS8oDhfykThhdOaLUlhZZtrbxnB82WrNY+VI+yDCgpluSlwxcgi2u6JJ/IAcw1pZY7J9SO1pqBpIhCT4uFB2yAMe+Iz85f9nePgrlq/lJwhUv6wE0p9ZGetgRap4dBOh5Wy8hVZ3e66Y2pjmCj0diXuponDKTYKSocry3WzpXjxptbyw0mKpHHLVFa8cfQnLaoKCRqO2vFxa7CWw8xiVQqdvXjMPxtCE+pASZO1BZGS0nBcKCuauucdijriJJDsd8Z3SrWsQZlKRSnotAKE8NvBuYp8tZAIRzWlN1shjxuSSUY42qYZAevDcMCfTKtTdY+1/U/URUGK+HiuGkzyVgKtafBsZhW5gwo2yALyg2ySiATZ6mVo1Y88wl8sJ/sYljA3TsKsA6MG0xNN2H3Yy42ASo1Neskhv0dXvoSNe4zOSaiN1l2MescLA+cSG/qWrRajTO4/xt8/Q7wQMkUOjvfJsdPrh+s14b1eTzMk/qrtU6U/gEOhMIg2LOC05y7I4+zd2ZuJrxPVxOEcP1se993Bg5oIXXL6EFYkUv+g5x5MGpQEXyNtiJqF3KLi9EF/T5ToVhqqZ/BmM5yNZLz3OZKFQ822FnVHissfzGjvPuhHjFqcyKYmWDj50NhBJSgjVRLOJfa1Kj/240lHKcKj2v7uSPDO3r7xr7RlMvdTJs3MhQmKp6Wm64UXG8VML9/RkVEI10uZprf/wmCEjXEwl7/QIfp31kce3MEw+zFiYhdf+UnUSXB8W/XIeBkfXpAJg7j56OBJx9E3WjjwO+kfrxYL9FzcrT14vHMjphR/xsSbQwSNCtQfjmLtBTmlsl/L4o/a6dBbvsIAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudHeader = '_9dfuut9';
export var aiDivider = '_9dfuuta';
export var aiScrollTip = '_9dfuutc';
export var allPlansLink = '_9dfuut4';
export var cloudScrollTipCaption = '_9dfuute';
export var cloudScrollTipTitle = '_9dfuutd';
export var collapsibleHeader = '_9dfuut5';
export var collapsibleHeaderCaption = '_9dfuut8';
export var collapsibleHeaderContent = '_9dfuut6';
export var collapsibleHeaderTitle = '_9dfuut7';
export var plansLayoutRoot = '_9dfuut0';
export var scrollArea = '_9dfuut1';
export var scrollBar = '_9dfuut2';
export var scrollThumb = '_9dfuut3';