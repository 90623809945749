import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W62ojNxT+n6dQAwEbIjN2HG/WaSC00BdooZSyFM3ojEfJjDSVNL6k5N17JI3n5svGS2CX9Z6bvu9c5/kVdqlmBRjyzzQpFv/uXubkvytCUq0K/4MQVbJE2N2SRI/4/3f8a9VQNQ2q96vnw4D35wNiOM2kSZUuluFnziz8NaKzmzExCcthFE2+LsbfefxUmGgfZDo+g3HhYx6G/iGEx6h+AsYvwzweDzaNopvDZJ16+Mx7Dx97L/roYx1g71eT/Sux9ymVEVYouSSp2AJ3VkIasHUFYpa8rrSqJKeJypVekjXTI0pZmgoJtKMuFGd5MPJvvVEhOWwHDrU0WHs7JkXBAoJ2EKb3UWEIEtkwzc1jD/bEeVCpJHgCHX8nG9gWKhZ5MPwOlZztQNNg38DrxEo+ki4uTImBUJvD1rPLxUpSYaEwS5KAtKCd+KUyVqQ7BIISabuqj+WtC+xvziyjaZXn1CQaQD5dW13B9beAmHEu5AoBkp9EUSptmbT9ABdktLZNGYff3eD8ocqhT7t9QhWTKhYJjeFNgMZxnc9uSYR/JvcPt2TabwGaCiSBHGHZK35guA98942cwzNZW8oSK9aBzVrAhvph8IWjEketBfn1GDuTCw6/KGvrATxC7gsSmN0ZAszA51DoPHoRA+aiL5dDI5XzUUNyfIrG4vNqdB4COwnh4dJMNonjPiRtkhotyWNPMB0KZkGwEdxm+wFrvD3NDMQqs0NdSEEhJD2unwU92zZ67MXE7d11Rii5m5XbxqJ+vDHYoMEsqg1itaVGvPl5jZXmuJFQ5DQpbgqngsFi8PIYczdurDY1hnnkd1LuzPa4ppPFY3cpuJfJbF76N9rlpgEvB/bfZSdArUHj9qMlk9A9BZ5VxrjaDHxLVTqfWlnbetaacVHhypzOAjRVWcej3Uinl965/vAJP2gSX6eDTmmknao1/t1aN4YD7FGvW2FwP1hsVF5Zn2KrSixFTVWHqK4yP3QP0vD1cK5fMro4bJfF0XaZ3we6eoXdH/v1tK9K582Vf3OD/Gmsgb1i97p/qJP4icvwBFKD32SIp9RAN5qVvq5Y/jRXGy/oe3XCZz78wXE9uKJOQUHyM5e37nzqUz6vc1zT8zJWWeVkK4cnFKGLZOIz/ptSGO9PYbNfM5FzDbJ7a0OgI74akK4JneCxcqEhqUcOk1DrT3Mzlun+8RY+WKcVb3pp3bWEmpl31SNzN/dR+NEL+HI810O4ON1VIdtJ9Gl5uk5ZbnAID76ahMs/RXrSmuEQ912H8zv0DXQ6gF8vA1xXdt/CzwVwwUjYIXh8OBn5WSc/P5GF649wuAaEBu3V7bu2C/bTAnI9MiwFyrC7qf9crHW37ebHT/P/ASOo/CwTDgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var confirmModalContainer = '_1cm6qyjj';
export var confirmModalContent = '_1cm6qyji';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalFooter = '_1cm6qyjh';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var promptModalContent = '_1cm6qyjk';
export var widthVar = 'var(--_1cm6qyj0)';