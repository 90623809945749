import "../../component/src/ui/notification/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE31TyW7bMBC95yvYAAXqAwPJS5oqyJcEOYzNkTQtRQrkyLJb5N/LxYvsODnOwjfvvRk+dOOwKNeP4t+dEGvrFDrpQNHgK/HU757P2ZEUt5UoL5Ke9xor4a0mldM76VtQdqzEFtwPKaGuyeAhKctZ6oLNn8bZwSi5sdq6Y2/mUswmA27U56l+oxDB3+8ecvAzKepBKTJNoP0YeZ+qT6mqyPca9pWoNSZVoKkxkhg7n5NBIDiOpd+DZ6r3gZBhNBw097BBuUYeEc0E+leCbpGaNnTNl9mvr0dtAiK6CQgkkIPln2KEKFC4whCiA9eQkS4zKIsL5SD8tpmil0Xx/XnC+BjfMHg5NXg9BSnii2RY2GvYfRlju0VX6xi2pFQ0SQjGHctzAbWm3pOPpbENSmSytRLGjg76hBoMl+OB3LJIk3Q8qWuLU6Onv+F1ucqpL53YJAFXLZdnolLLDScW6QZ764nJmko41MC0xcvrrgQ7MEGRC7s5H3VUZ3AyBl8VMMhgCfDLPbsB79/y+Z4GwDr8sYHTALb9kakQt4nXnxFfzcQ36nrrGCKl04NmakYasPrg6sWHzvlu9nEd88wkaxqJW0nh07yJ46RcSPcfxBLrk9r8WaXGOuAs0lrf/wNBstyLoQQAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = 'mwu31bc';
export var actionButton = 'mwu31bd';
export var actionTextColor = 'var(--mwu31b3)';
export var card = 'mwu31b6';
export var cardBorderColor = 'var(--mwu31b2)';
export var cardColor = 'var(--mwu31b0)';
export var cardForeground = 'var(--mwu31b1)';
export var cardInner = 'mwu31b7';
export var closeButton = 'mwu31be';
export var closeIcon = 'mwu31bf';
export var closeIconColor = 'var(--mwu31b5)';
export var headAlignWrapper = 'mwu31b9';
export var header = 'mwu31b8';
export var icon = 'mwu31ba';
export var iconColor = 'var(--mwu31b4)';
export var main = 'mwu31bg';
export var title = 'mwu31bb';