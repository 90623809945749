import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61XW2+bMBR+76+wNk1qHxwBaXqh0rR/Mhl8SLwaG9mmSTf1v+9gTAIUEjLtIUow5/J95+r8eIX3wrASLPkZ28fYJXpN/twQEn3zX4Q4w5QttClTYrRjDm4jDtu7F3z5gZ/NGcH4aSAaR2dk1w892Y+bVYcmIiupt9rrFVo5asVvSMkbM7eUsqIQCqg/39G11/YPexDbnUvJQxQ1Z7mW2oyUMsnyV69RMrMVipqgUh2awzcwTuRMUibFVqWkFJxLeBlC+06qtBDGOqoL6t4rWAJzMwszAMm0cxojc98ikY3uLkgmT+0hF7aS7D0lhQR/4GFS4aC0KclBOTATYCW7Duv9LNYJVD1vsTdeMc6F2h4JraMWfKWtcEJjVA1I5sTbMK7xai/cTtcIQCg3aSmJPjmULAPphY+xyaTOX+fy7+CAzCHXijPzTr3I3XwWzgbKlgvjlIxgJ5eTEGyfYsYyq2XtoDkdlwoU6GWJ3xUYo433PhUc//YYk4HinhmFqZhVDe+nlO+90l5wt0tJvGkxdxi756niaIgaDoYaxkWNBY5TpznV2KWF1PuU7LA9QXlJbOut0bXidLLtW0NT6NKUFdg2gRl2kEJYX7689DAnI8zJGHM/Ob1shNnifztdhV9d9nrNjxZqp6d5fC2KonnzmwrF4YBpnYvMkFUGOGnhDK0kGtLqni+FUtZwpjZb+iFTHdXweKQQ+4h024BqDFQThEY36PjhpkTJWg+nLRWvNpYIhVBw6vl6Z8ORt7ncXBmzLYVBu8wvlzAbfArX4xm0IWy2K9D+61TRPUyOt/tlg/JxOO66VfCrtk4UzUgLybYVyzFd4PbQ9silZfG0cDAsG11+0oY12jkbRRxzeXUfHUuqjdWwf3pknj2ZztM6mVmgn6J2gjoZrgvF8ExWBiz41uFw2mRTO2JJEIeXlPG+ZQOOeNF62/XHFj7v/xPpJe00ALbaG1ZVYaxefW1B/YDN65fsQAOpx2gccpR1wskFFxsvtvQa9ulyg35snZ1cXZa/fi5klxL6bTKfzTflwkDethFarkv1L5nuYcn7a3vdVfqPErhgxOYGhwpOaE5ue9mJowTvJHdec1Sl51AeR6HnWB2Oq2iCgLdiHTOu+4MxV3XnPA70eBA/xlVpBd1fkvOENw3SEd88mBtlDY39BZTtZ/x3DQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authCodeContainer = '_1s71t2o6';
export var authCodeErrorMessage = '_1s71t2o8';
export var authCodeWrapper = '_1s71t2o7';
export var authContent = '_1s71t2o5';
export var authInputWrapper = '_1s71t2o1';
export var authPageContainer = '_1s71t2oa';
export var formHint = '_1s71t2o2';
export var hideInSmallScreen = '_1s71t2od';
export var input = '_1s71t2oc';
export var loading = '_1s71t2o4';
export var modalHeaderWrapper = '_1s71t2o0';
export var resendButtonWrapper = '_1s71t2o9';
export var signInPageContainer = '_1s71t2ob';