import "../../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE51V247aMBB936/wS6WllVHCrdug/ZJqVZl4Qtw6tuU4ELbqv3fsEOIAWZU+APJ4fObMmQvzH+nyXVapTL5z5hiFyrjTq7MNvJHfT4TkWmqbkQOzz5SyohAKqJEsh1JLDpaG+9n26c/TvEdKw0MuavQ7ZaSQ0G7R4H8pFxZyJ7TKPHJTKX9zFNyVGUmT5JM/7pnBw8K0I9TFfdTgvDFDhKP1Fv99Ax2hLQOaYZwLtc9I4n2NrkVHzYJkThzAW51GuJzJ/Jku52vTki9nMSzjoqVGG4MyMJWX2tISxL50M/KZ0HTmX0soXEa6lxGfgDcNE7xmGCnFzAKOPoAtpD5mpBScgxols/pAmtWkNJfk0wSz6vQmZMfyX3urG8XpvdKXnsil6IRUQp2TxlDXJVsHXiE0QiL39DZuUL5idi9QdzqYdtpie2VEaRXKoBsnkcBgKLRytBbvaEr7JL2pYJWQqIJQJVjhtkMPR5bbLJ1lqjbMgnLjHLIsavf/HYnNoyMRavfSpTV0qbf0n063dlA/STrj0CqscXpE4+t9Gj+b2onihMSVw/wzgkLkQHfgjgCBDpNir6hwUNXIEn3A3jK7qspIn2Bv69nF53imvU6S7YSmDlrEAmTFmT3dk/Ul5NMnTNthOqamOVZs0Ss2iB1hf/u3kmFfPyRQH3y5uo6NIjS29ioYLXqAbgzCkmjq8zTHJLulXei8qYFHa/uhMY4AWaco1l+4U9iLcbQsvCNT3ukYquNWO+bgVRtQbx957+7siug6D9fn7blIxlr255tiTVTlpt/7qyggjwOmm3HA/nxVnXX4k/kLulfUHlIHAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_13zlm1l2';
export var searchInput = '_13zlm1l5';
export var spacer = '_13zlm1lb';
export var tagColorIcon = '_13zlm1ld';
export var tagColorIconWrapper = '_13zlm1lc';
export var tagEditIcon = '_13zlm1la';
export var tagSelectorItem = '_13zlm1l9';
export var tagSelectorTagsScrollContainer = '_13zlm1l8';
export var tagsEditorRoot = '_13zlm1l1';
export var tagsEditorSelectedTags = '_13zlm1l4';
export var tagsEditorTagsSelector = '_13zlm1l6';
export var tagsEditorTagsSelectorHeader = '_13zlm1l7';
export var tagsInlineEditor = '_13zlm1l0';
export var tagsMenu = '_13zlm1l3';