import "../../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VVTXObMBS8+1fo0pnkIA92Ysclk1/SyUGgB7xGSIwkgtNO/3sfkmODXTO4014Affjt7tuVvFRP+bapE/ZzwZhE1yjxkbJCwf6ZJvo3l2gh92h0ynKj2lr3Kx1KX6VslSRf+uH31nksPnhutAftaSc9wT4vfi2WEWE1D8Ga7o/1XCNy4Bn4DiAQEApLzdFD7U5ojDVCStRlyrbNniX9TC1siZpnxntTE2NaGNBa30RrNmgpmjDqv03rFWpImTYa+glvhXYYC2cifyutabVkyXLtGAgHQ3rfpPCCV1hWBF3510D39KOUvQt7x7koCoLgpwXugHonhe17qIy9HxR9uNXsoOYxqplu52MoHRDPqCHROVIhMDKWO/wBFyU2V0t42HveWKyHqkalRtvjfB32mHewhTJdyiqUMiYolDstgFLYOHQh3RV5zEPmets6K5ohw2hKq7WoQb5428LrVdLU4xwqoyTYSye201ovPZyndpr+UwA9O8AV9PFKY3ozY3u63pDtK4q0MwrlWdLilgtFu1A8RImCSG1dDRa/TsulRIpMwVyxezcEFv+nlWdx342imv2LyyMcrl08XHMYhe/uYNcmCYbdJPt4WxHo4fCRThJ5iMR6m4xU5tHSKWoV39wPgmOFxNYdr4yLBl3pxNU/kWNaH9ax4mdaP8eh466yqN9GEZ4b37++Un8DP81BQT8HAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var divider = 'l7c6pm7';
export var listHeader = 'l7c6pm1';
export var main = 'l7c6pm0';
export var metaList = 'l7c6pm3';
export var propertyDocCount = 'l7c6pm6';
export var propertyIcon = 'l7c6pm4';
export var propertyName = 'l7c6pm5';
export var propertyNameIconEditable = 'l7c6pmc';
export var propertyRequired = 'l7c6pm9';
export var propertyRow = 'l7c6pm2';
export var propertyRowNamePopupRow = 'l7c6pmb';
export var spacer = 'l7c6pm8';
export var subListHeader = 'l7c6pma';