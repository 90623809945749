import * as XLSX from 'xlsx';
import axios from 'axios';
import * as Y from 'yjs';
// export const getBlocksInfo = async (blocks, host) => {
//   const blocksInfo = [];

//   blocks.forEach(async item => {
//     if (item.flavour === 'affine:paragraph') {
//       let text = item.text.toString();
//       blocksInfo.push({
//         block_type: item.flavour,
//         block_content: text,
//       });
//     }
//     if (item.flavour === 'affine:image') {
//       let sourceId = item.sourceId;
//       const file = await host?.doc.blobSync.get(sourceId);
//       blocksInfo.push({
//         block_type: item.flavour,
//         block_content: file,
//       });
//     }
//     if (item.flavour === 'affine:list') {
//       blocksInfo.push({
//         block_type: item.flavour,
//         block_content: { label: item.text.toString(), checked: item.checked },
//       });
//     }
//     if (item.flavour == 'affine:attachment') {
//       let sourceId = item.sourceId;
//       const file = await host?.doc.blobSync.get(sourceId);
//       console.log(file, item, item.type, 'file');
//       if (
//         item.type ==
//         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//       ) {
//         // 将文件内容转换为 ArrayBuffer
//         const arrayBuffer = await file.arrayBuffer();
//         // 使用 XLSX 解析 Excel 文件
//         const workbook = XLSX.read(arrayBuffer, { type: 'array' });
//         // 假设我们要读取第一个工作表
//         const firstSheetName = workbook.SheetNames[0];
//         const worksheet = workbook.Sheets[firstSheetName];
//         console.log(firstSheetName, worksheet, 'worksheet');
//         debugger;
//         // 将工作表转换为 JSON
//         const jsonData = XLSX.utils.sheet_to_json(worksheet);
//         blocksInfo.push({
//           block_type: item.flavour,
//           block_content: JSON.stringify(jsonData),
//         });
//       }
//     }
//   });
//   return blocksInfo;
// };

const getBlocksInfoFromOriginY = blocks => {
  let infos = [];
  blocks.forEach(block => {
    let block_info = block.content?.type?._map;
    let block_type = block.content.type._map
      .get('sys:flavour')
      .content.arr.join('');
    let block_id = block.content.type._map.get('sys:id').content.arr.join('');
    if (
      block_info.get('sys:flavour').content.arr.join('') === 'affine:paragraph'
    ) {
      infos.push({
        block_type,
        block_id,
        block_content: block.content.type._map
          .get('prop:text')
          .content.type.toDelta()
          ?.map(item => item.insert)
          .join(' '),
      });
    }
    if (block_info.get('sys:flavour').content.arr.join('') === 'affine:list') {
      infos.push({
        block_type,
        block_id,
        block_content: JSON.stringify({
          label: block.content.type._map
            .get('prop:text')
            .content.type.toString(),
          checked: block.content.type._map.get('prop:checked').content.arr?.[0],
        }),
      });
    }
    if (
      block_info.get('sys:flavour').content.arr.join('') === 'affine:database'
    ) {
      let tableData = [];
      const columnDict = block_info
        .get('prop:columns')
        .content.type.toJSON()
        .map(item => {
          if (item.type === 'multi-select' || item.type === 'select') {
            return {
              [item.id]: {
                name: item.name,
                type: item.type,
                optionDict: item?.data?.options?.reduce((acc, item) => {
                  acc[item.id] = item.value;
                  return acc;
                }, {}),
              },
            };
          }
          return { [item.id]: { name: item.name, type: item.type } };
        })
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          acc[key] = value;
          return acc;
        }, {});
      const cells = block_info.get('prop:cells').content.type.toJSON();

      Object.values(cells)?.forEach(row => {
        tableData.push(
          Object.values(row)
            .map(item => {
              if (columnDict[item.columnId]) {
                if (columnDict[item.columnId]?.type === 'multi-select') {
                  console.log(columnDict, item);
                  return {
                    [columnDict[item.columnId]?.name]: item.value.map(
                      key => columnDict[item.columnId]?.optionDict?.[key]
                    ),
                  };
                }
                if (columnDict[item.columnId]?.type === 'select') {
                  console.log(columnDict, item);
                  return {
                    [columnDict[item.columnId]?.name]:
                      columnDict[item.columnId]?.optionDict?.[item.value],
                  };
                }
                return {
                  [columnDict[item.columnId]?.name]: item.value,
                };
              }
              return null;
            })
            .filter(Boolean)
        );
      });
      infos.push({
        block_type,
        block_id,
        block_content: JSON.stringify(tableData),
      });
    }
  });
  return infos;
};
const getContentFromBlob = blobStr => {
  const binaryString = atob(blobStr);

  const length = binaryString.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const binaryData = new Uint8Array(bytes);
  const ydoc = new Y.Doc();
  Y.applyUpdate(ydoc, binaryData);
  const blocks = ydoc.get('blocks');
  let infos = [];
  blocks._map.forEach((block, key) => {
    if (
      block.content?.type?._map.get('sys:flavour').content.arr.join('') ===
      'affine:note'
    ) {
      const childrenKey = block.content.type._map
        .get('sys:children')
        .content.type.toArray();
      const children = childrenKey.map(key => blocks._map.get(key));
      // console.log(block.content.type._map.get("sys:children").content.type.toArray());
      let cInfos = [];
      if (children?.length) {
        cInfos = getBlocksInfoFromOriginY(children);
      }
      if (cInfos?.length) {
        infos.push(cInfos);
      }
    }
  });
  infos = infos.flat(Infinity);
  return infos;
};

export const getBlocksInfo = async (blocks = [], host) => {
  const blockPromises = blocks.map(async item => {
    if (item.flavour === 'affine:paragraph') {
      let text = item.text.toString();
      let delta = item?.text?.yText?.toDelta();
      let linkIds = delta
        ?.filter(item => item?.attributes)
        .map(item => item?.attributes?.reference?.pageId);
      linkIds = linkIds.filter(Boolean);
      let reqPromise = linkIds.map(async id => {
        let response = await axios.get(
          `/back_api/database-services/get_snapshot_by_guid/${id}`
        );
        let block_content = [];
        if (response.data.blob) {
          block_content = getContentFromBlob(response.data.blob);
        }
        return block_content;
      });
      let contentList = await Promise.all(reqPromise);
      contentList = contentList.filter(Boolean)?.flat(Infinity);
      if (linkIds?.length) {
        if (text) {
          return [
            {
              block_type: 'affine:embed-linked-doc',
              block_content: contentList
                ?.map(item => item.block_content)
                ?.join(' '),
            },
            {
              block_type: item.flavour,
              block_content: text,
            },
          ];
        }
        return [
          {
            block_type: 'affine:embed-linked-doc',
            block_content: contentList
              ?.map(item => item.block_content)
              ?.join(' '),
          },
        ];
      }
      return [
        {
          block_type: item.flavour,
          block_content: text,
        },
      ];
    }
    if (item.flavour === 'affine:image') {
      let sourceId = item.sourceId;
      const file = await host?.doc.blobSync.get(sourceId);
      return [
        {
          block_type: item.flavour,
          block_content: file,
        },
      ];
    }
    if (item.flavour === 'affine:list') {
      return [
        {
          block_type: item.flavour,
          block_content: { label: item.text.toString(), checked: item.checked },
        },
      ];
    }
    if (item.flavour === 'affine:attachment') {
      let sourceId = item.sourceId;
      const file = await host?.doc.blobSync.get(sourceId);
      console.log(file, item, item.type, 'file');
      if (
        item.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        item.type === 'application/vnd.ms-excel'
      ) {
        const arrayBuffer = await file.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        console.log(firstSheetName, worksheet, 'worksheet');
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        console.log(jsonData, 'jsonData');
        return [
          {
            block_type: item.flavour,
            block_content: JSON.stringify(jsonData),
          },
        ];
      }
    }
    if (item.flavour === 'affine:database') {
      let tableData = [];
      const columnDict = item.columns
        .map(item => {
          if (item.type === 'multi-select' || item.type === 'select') {
            return {
              [item.id]: {
                name: item.name,
                type: item.type,
                optionDict: item?.data?.options?.reduce((acc, item) => {
                  acc[item.id] = item.value;
                  return acc;
                }, {}),
              },
            };
          }
          return { [item.id]: { name: item.name, type: item.type } };
        })
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          acc[key] = value;
          return acc;
        }, {});
      const cells = item.cells;
      Object.values(cells)?.forEach(row => {
        tableData.push(
          Object.values(row)
            .map(item => {
              if (columnDict[item.columnId]) {
                if (columnDict[item.columnId]?.type === 'multi-select') {
                  console.log(columnDict, item);
                  return {
                    [columnDict[item.columnId]?.name]: item.value.map(
                      key => columnDict[item.columnId]?.optionDict?.[key]
                    ),
                  };
                }
                if (columnDict[item.columnId]?.type === 'select') {
                  console.log(columnDict, item);
                  return {
                    [columnDict[item.columnId]?.name]:
                      columnDict[item.columnId]?.optionDict?.[item.value],
                  };
                }
                return {
                  [columnDict[item.columnId]?.name]: item.value?.toString(),
                };
              }
              return null;
            })
            .filter(Boolean)
        );
      });
      return [
        {
          block_type: item.flavour,
          block_content: JSON.stringify(tableData),
        },
      ];
    }
    // 如果没有匹配的 flavour，返回 null 或适当的默认值
    return null;
  });

  // 等待所有 Promise 解析完成
  let blocksInfo = await Promise.all(blockPromises);
  blocksInfo = blocksInfo.flat();
  // 过滤掉可能的 null 值
  return blocksInfo.filter(Boolean);
};
