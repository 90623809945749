import "../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VU227bMAx9z1cIBQp0DwqU61IX+5JhGJSIttXKliHRcbKh/z5JtmzHMNJtQF9kmaTIcw4pLX/udlv2embk94KQSluJUpcJ4UerVY3w4qxC2krxa0JSBRdv8F8qpIFTG3xyoUXpPa+1RZle6UmXCCU6l1vBeBdXMiupRCjs2JyDzHIXuGLs0f83UmA+/KLhZY9JKcKWG0uAW6CypLrGl8X7YtlxWAUOn4A2ZLDIDf4F4oxXCTlUoXTFhZBl5pz71lDwC+3it4y1tjHF3n+f6HfBkVOb68bDOr19e0BTw8OPIMCoxr6tMZxch4gJYn0GkyrdJCSXQkCQBuGCtDGei18DT20EPRrgbwkJH+ot4+ybkD3qw2vUkXO0sTnCrXOGMfG90iYhZ26eKOVpKkugAZoF1zTBzfVLaLFrILXyFzhS21ZV5UNj2fW6NYa4pjO6BtyA/1DUPttU1e1U98lobGKr26myoNJh1oY0u5Dm/7nckasysohiuU3mFD5qRF24o7dc9vOXqDuEuhqGeW7wNx26f30IBgBf25co3hwHL96msTJdmcJhijDWk64c5pl8UP45nBqPaNin2hSzE9oJoyDF/tpHRJ1O/YOxvwX4fHfi+rIJMRo5wtPqwARkrofvfwBrfx3DtgUAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = '_5540jv7';
export var actionContainer = '_5540jv6';
export var actionContent = '_5540jv8';
export var arrowIcon = '_5540jv9';
export var errorContainer = '_5540jv1';
export var errorLayout = '_5540jv0';
export var illustration = '_5540jv4';
export var label = '_5540jv2';
export var scrollArea = '_5540jv3';
export var text = '_5540jv5';