import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useEdgesState,
  addEdge,
  useViewport,
  useReactFlow,
  ReactFlowProvider,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { PlusOutlined } from '@ant-design/icons';
import { uuidv4 } from '@blocksuite/affine/store';

import ResizableNode from './ResizableNode';
import { Button, Dropdown, Row, type MenuProps } from 'antd';

const nodeTypes = {
  ResizableNode,
};

const FlowChartInner = ({ nodes, setNodes, onNodesChange }) => {
  const nodesRef = useRef();
  nodesRef.current = nodes;
  const { x, y, zoom } = useViewport();
  const { screenToFlowPosition, fitView } = useReactFlow();

  useEffect(() => {
    fitView({ padding: 0.2, includeHiddenNodes: false, duration: 800 });
  }, [fitView]);

  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    if (nodes?.length && isFirst) {
      setIsFirst(false);
      setNodes(
        nodes.map(item => {
          if (item?.data?.nodeType === 'text') {
            item.data = {
              ...item.data,
              onChange: onMdChange,
              onDelete: onDelete,
            };
          }
          if (
            item?.data?.nodeType === 'img' ||
            item?.data?.nodeType === 'img_single'
          ) {
            item.data = {
              ...item.data,
              onChange: onImgChange,
              onDelete: onDelete,
              onDeleteImg,
            };
          }
          if (item?.data?.nodeType === 'html') {
            item.data = {
              ...item.data,
              onChange: onMdChange,
              onDelete: onDelete,
            };
          }
          return {
            ...item,
          };
        })
      );
    }
  }, [isFirst, nodes]);

  const MenuItems: MenuProps['items'] = [
    {
      label: '文字组件',
      key: 'text',
    },
    {
      label: '多选图片',
      key: 'img',
    },
    {
      label: '单选图片',
      key: 'img_single',
    },
    {
      label: '网页组件',
      key: 'html',
    },
  ];

  const onMdChange = (id, text) => {
    setNodes(prevState =>
      prevState.map(item =>
        item.id === id
          ? { ...item, data: { ...item.data, content: text } }
          : item
      )
    );
  };

  const onImgChange = (id, text) => {
    setNodes(prevState =>
      prevState.map(item =>
        item.id === id
          ? {
              ...item,
              data: {
                ...item.data,
                content: [...(item.data.content || []), text],
              },
            }
          : item
      )
    );
  };
  const onDelete = id => {
    setNodes(prevState => prevState.filter(item => item.id != id));
  };

  const onDeleteImg = (id, uid) => {
    setNodes(prevState =>
      nodesRef.current?.map(item => {
        if (item.id === id) {
          const newContent = item.data.content.filter(img => img.uid !== uid);
          return { ...item, data: { ...item.data, content: newContent } };
        }
        return item;
      })
    );
  };
  const handleItemClick = ({ key }) => {
    const newId = uuidv4();

    // 计算视口中心点在画布中的位置
    const centerX = x + 30 * zoom;
    const centerY = y + 30 * zoom;

    // 将画布坐标转换为视口坐标
    const position = screenToFlowPosition({ x: centerX, y: centerY });

    if (key === 'text') {
      setNodes([
        ...nodes,
        {
          id: newId,
          type: 'ResizableNode',
          data: {
            content: '输入内容',
            nodeType: key,
            onChange: onMdChange,
            onDelete: onDelete,
          },
          dragHandle: '.drag-handle__custom',
          position,
        },
      ]);
    } else if (key === 'img' || key === 'img_single') {
      setNodes([
        ...nodes,
        {
          id: newId,
          type: 'ResizableNode',
          data: {
            content: [],
            nodeType: key,
            onChange: onImgChange,
            onDelete: onDelete,
            onDeleteImg,
          },
          dragHandle: '.drag-handle__custom',
          position,
        },
      ]);
    } else if (key === 'html') {
      setNodes([
        ...nodes,
        {
          id: newId,
          type: 'ResizableNode',
          data: {
            content: '',
            nodeType: key,
            onChange: onMdChange,
            onDelete: onDelete,
          },
          dragHandle: '.drag-handle__custom',
          position,
        },
      ]);
    }
  };

  const onNodeDragStart = useCallback(() => {
    console.log('Drag start');
    setNodes(prevNodes =>
      prevNodes.map(node => ({
        ...node,
        data: { ...node.data, isInteracting: true },
      }))
    );
  }, [setNodes]);

  const onNodeDragStop = useCallback(() => {
    console.log('Drag stop');
    setNodes(prevNodes =>
      prevNodes.map(node => ({
        ...node,
        data: { ...node.data, isInteracting: false },
      }))
    );
  }, [setNodes]);

  return (
    <>
      <Row style={{ position: 'fixed', zIndex: 1000 }}>
        <Dropdown
          menu={{ items: MenuItems, onClick: handleItemClick }}
          trigger={['click']}
        >
          <Button type="link" onClick={e => e.preventDefault()}>
            <PlusOutlined />
            添加组件
          </Button>
        </Dropdown>
      </Row>
      <ReactFlow
        style={{
          height: '100%',
        }}
        nodes={nodes}
        onNodesChange={onNodesChange}
        onNodeDragStart={onNodeDragStart}
        onNodeDragStop={onNodeDragStop}
        nodeTypes={nodeTypes}
      >
        <Controls />
        <MiniMap zoomable pannable />
        <Background gap={12} size={1} />
      </ReactFlow>
    </>
  );
};

// 创建一个新的组件，用 ReactFlowProvider 包裹 FlowChartInner
const FlowChart = ({ nodes, setNodes, onNodesChange }) => (
  <div style={{ width: '100%', height: '100vh' }}>
    <ReactFlowProvider>
      <FlowChartInner
        nodes={nodes}
        setNodes={setNodes}
        onNodesChange={onNodesChange}
      />
    </ReactFlowProvider>
  </div>
);
export default FlowChart;
