import "../../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-tags.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VVbW/aMBD+3l/hL5WKVFch0I0G7ZdM02TiS3KrsTPbKbBq/322k0BsKBXaFxTOvrvnee7FTz/nTC/r39WcvN8R0iqDFpUsiAbBLL7B2ll3yG1TkHmW3fu/DWDd2NP/LUo62hZ5u1/f/b17GuPmIS5H0wp2KEglYO99mMBaUrSwNQUpQVrQkdsiuKk30JVQO7ovSIOcg1x/CDJFVbO2IKsEzTKE9SDoTvsL/nc9mjhqKPvIpRLdVp4hDdeMZdoeUyyTFM+XCfcB6QnVBxpM6bGNcV72Ir0tcxTiuljN5NFVCJI95cb9LAwBZoC6KqnORljnReMlJgn4Sew3ph8oHY+zWeT+JemZKV6rHNHMfwmo7PCpew7ZJUKqZSXaw3B6iYu3b1j5WmvVSV4QgRKYprVmHJ16Dy8Zh/qxd22ZdiaS3T8OFFhVues08KWuGErTCoUAPiPL7H52XZae6BHhPLr9NRyOdPK8L+5ZA/gpGUTNrlT/V2csVgcH0VmkC+iolEA3YHfg23+auBDMWFo2KHhft1MKX8IhROSzumEcPwfjis84R1k7TmNXB22LWHULe0tbjVumD734s1BMpflYjcRjehQXZ/WdM8topcrOAP9mdQc/Aqkr0dLUk3Av/UZwBKnBP1AQlA1otBN8vr864zu1PRvlZW8abo4DGRmNPQgX140G8rSFY87HgwtaTec9H6RORyjIPO7MgoAQ2BoMc7NrXHFpKF9BpOrX3kQGlsoQIevt21lUcUfdQTmXZAB3K5gTnUWWrNTN5+Aa+jxLN8xpMbkuLl8P0ViiFME5DMAkVxlyDUonOq+iuo5t8dyL3z8NjUb5GvJPYvLoMZs+ZbeL5JretRQI91B5s4QoE/zfeJ+Ojq2WdFp+iwSuLzpt/DS2Cs+eeBi27Pu1mZjsaz+3/wAVmq70rwgAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hoverMaxWidth = 'var(--_1ar4gqf0)';
export var innerBackdrop = '_1ar4gqf6';
export var innerContainer = '_1ar4gqf5';
export var root = '_1ar4gqf1';
export var showMoreTag = '_1ar4gqfb';
export var tag = '_1ar4gqf7';
export var tagIndicator = '_1ar4gqfc';
export var tagInline = '_1ar4gqf9 _1ar4gqf8';
export var tagInnerWrapper = '_1ar4gqf8';
export var tagLabel = '_1ar4gqfd';
export var tagListItem = '_1ar4gqfa _1ar4gqf7';
export var tagRemove = '_1ar4gqfe';
export var tagsContainer = '_1ar4gqf2';
export var tagsListContainer = '_1ar4gqf4 _1ar4gqf2';
export var tagsScrollContainer = '_1ar4gqf3 _1ar4gqf2';