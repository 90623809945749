import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import './detail.css';

import FlowChart from './components/FlowChart';
import { useNodesState } from '@xyflow/react';
const EditModal = ({
  open,
  setOpen,
  containerId,
  pageId,
}: {
  open: boolean;
  containerId: string;
  pageId: string;
  setOpen: (boolean: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);

  useEffect(() => {
    if (pageId && containerId && open) {
      setLoading(true);
      axios
        .get(
          `/back_api/database-services/get_container_detail?guid=${pageId}&container_id=${containerId}`
        )
        .then(async result => {
          let data = JSON.parse(result.data.detail_content);
          console.log(data?.human_edit, 'data?.human_edit');
          let editData = data?.human_edit;
          editData = editData?.map(item => {
            if (item?.type !== 'ResizableNode') {
              return {
                id: item.id,
                type: 'ResizableNode',
                data: {
                  content: item.content,
                  nodeType: item.type,
                },
                dragHandle: '.drag-handle__custom',
                position: {
                  x: item.x,
                  y: item.y,
                },
                measured: {
                  width: item?.width,
                  height: item?.height,
                },
              };
            } else {
              return item;
            }
          });
          setNodes(editData || []);
        })
        .catch(err => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [containerId, pageId, open]);

  const handleCancel = () => {
    setOpen(false);
    axios
      .post(`/back_api/database-services/insert_or_update_details`, [
        {
          guid: pageId,
          container_id: containerId,
          detail_content: JSON.stringify({
            human_edit: nodes,
          }),
        },
      ])
      .then(result => {})
      .catch(err => {})
      .finally(() => {});
  };
  return (
    <>
      <Modal
        zIndex={2000}
        loading={loading}
        wrapClassName="fullscreen-modal"
        open={open}
        footer={null}
        onCancel={handleCancel}
        onClose={handleCancel}
        width="100%"
        style={{ top: 0, paddingBottom: 0, width: '100vw', maxWidth: '100vw' }}
      >
        {open && (
          <FlowChart
            nodes={nodes}
            setNodes={setNodes}
            onNodesChange={onNodesChange}
          ></FlowChart>
        )}
      </Modal>
    </>
  );
};

export default EditModal;
