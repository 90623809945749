import "../../core/src/components/affine/auth/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/auth/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61WTY/bIBC951eg7aV7IHKy+dg66qV/o6oqYsY2iQMW4E3Sqv+9gOMsxnbWlvZkCZg3jzczD89/L5LDdnVcRejvDKETkRnjWIsyRi9RednN/s3mzZGFdyRGpNJi9x6yF1qLU4xWNgghDReNScEyczIBrkG2oJYD2RBKRCFkjN6I/IoxSVPGATswBYnglMgrdkee7eFUcLPB/kAQ4NYvyp0p7EIOLMt1jBbzdYvHy3QeuUEqLJrJIiGTouIUS6CfQ2iJiKPUl94EHu+Xb8XM7VYdV0llA0vBatGnQa0cSCkU00zYIu+VKCoNFkfWjKNdoBheboJGWfuqNo3RaafNuIsitCfJsdY5RloSrkoiTUu5LSEpSHwDCTYnF2O5rGtfKYOpoIDELHLBocV763g/BFen58m9/LHojZJRi86ro9NcYbutr0CZKgtyjVFagFuwX0yZNHdySUzi6sTtzqFSmqWWiukYrt/nFSE3wJhpOCl/+aFLfHN8OvkfZMmIwXmteffW42OtvfzD8+OkLyU79ZjI+ZZyE7XV3Tu0M6M6N962HmVuSSsmGo4Z10U3tQtIrV9shu1pX1TQvdEquBF17PrpTKnaQG+MGboWv3XUcZTNTefwaQlbDfpbbQyFe4tFXosFlEZ6aVlJwOfcKBG4Fe4tkvEo6o89oZTxzEx183TePE0Syip1f1CnVsKX6SclmmCm8B4KBm8gvz9pWcHTL6dfl/CXl+1qsV60QNJ+rd3sLm5zOtASodZ+qZvQoNT1spc+q03XZDWbPmjkSXaPLi/IWCijwbNCruYQ44pR+NFE2E/bQPLHBnL37pEPvofMRlnT6B+Jbs96uQ6Pc9kndvoFjqNAvYBiYEIHH6P+Xv4Pn9Rt6aYKAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accessMessage = '_1cj74k4d';
export var authMessage = '_1cj74k42';
export var authModalContent = '_1cj74k40';
export var captchaWrapper = '_1cj74k41';
export var errorMessage = '_1cj74k43';
export var forgetPasswordButton = '_1cj74k47';
export var forgetPasswordButtonRow = '_1cj74k44';
export var linkButton = '_1cj74k46';
export var resendCountdown = '_1cj74k4b';
export var resendCountdownInButton = '_1cj74k4c';
export var resendWrapper = '_1cj74k48';
export var sendMagicLinkButtonRow = '_1cj74k45';
export var sentMessage = '_1cj74k4a';
export var sentRow = '_1cj74k49';
export var skipDivider = '_1cj74k4f';
export var skipDividerLine = '_1cj74k4g';
export var skipDividerText = '_1cj74k4h';
export var skipLink = '_1cj74k4j';
export var skipLinkIcon = '_1cj74k4k';
export var skipSection = '_1cj74k4l';
export var skipText = '_1cj74k4i';
export var userPlanButton = '_1cj74k4e';