import "../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W227bOBB971cIBhZIsGAg2a6TKAjQ/yiKBS2OLNY0KZCUXXfRf98hdTEpy6p3sw+BQ3F4ODxz5vJlD+dS0wOY5K/sXNSb42mb/P0pSdI//E+SqJoW3J7zJHvD9S/828zsPV/vpf3ey8y515lzWXrz4K9PT73bmTcplFA6T7isQHP7FhosvQHjphb07EwEl0BKAT8cFhV8Jwm3cDB5UoC0oN3nrdIMNNGU8QY31rU3tvDDEn8iTwSU9m3i5iQ5cWar3LvvlgcuSQV8V9k8WaUtUGMQ3ICAAj9KJcEjNdo4qFrx3o2aMsblDknpXSiVtMTwn5AnR6ofCKFl6d/jvx8e/SlluOUKndQgqOVHD3+geoeuWFUjnAMLOcordQTtmdrSYr/TqpFsdIU3If69j/Hpr4xaSmjhrnpfWN3A4tvHsDBcdCuARWg9QQxK2oiQ/QjbR8lAoSSj+jxckvTEEjhinE1P/MTlmu6Qql10+UWfT5+jQ6tYX78RVicOL3Jn2kl6OmQ7irHKlqNgrf2F048JkejWKNFY/9WptbvVC4BknRIHsbbLXqjZWCGfWxIwaqVQpzypOGMgh6S4bIAQvDbcePAK308MMgfOwZOm9Qw1AR03w1prjjo++3D6PO4djkyDnVhdm38VK91Cp7dpjYvW7/XVpdnIn7i6jVG4xIupiJ0YPgboz9OP+94Yy0uXBwgrbfjAeY0uR5ro11PB4YgeBSeoU8uxll68o73yNiPldet7gzTzvFERX3ZFXFPZ0+j/L5U+YFYvzZ2PC1/SFgw8JGhtRuVqAMekVpZaeCCvKYPdJMRkwQtqzuougb18qI6/Xtye5CcBapANSVQTt9jXj9NAY/FevYzmSgqMcsUFGzf061QovEnY8brgdw2VtPWwpaU/lN5sngEym+f2stELJug/Q151iTTS52aUJtDV+Vl/oKXeanCRMch74azfFwe6h5auxbfWBueNsu1twHzBfl+UVBiMU05L26lmSKPFIsrCrVDF/nYh/ImXs6F83ym9vi1NhaEdUwLWCiqKBz8SkqsDj3HrcnPX/01Ru69qkFOsUYmxblm5jNOZQW2WTp7gexXVkVtlG95+wnuZ1MQwJ2CB0FB0QlAnorEGaDMzJZS3g3rF6J/zjOLE0zl3U/at15fAbpW16jDbOy/dNfJ6uoBd1QN8nh83/2OpK+8aWX3pqqlG7qLTVdjAVsu4gfXrsAOuR8HhHiCwyMbJ//3K4rmFjaaeZXeXAOv6gpu0vJhI+rReBW6cOvt1GtO9v6qTbv8fcTW0yx0OAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1ycp6vwa';
export var collapsedIcon = '_1ycp6vw9';
export var collapsedIconContainer = '_1ycp6vw8';
export var contentContainer = '_1ycp6vwc';
export var draggedOverEffect = '_1ycp6vwe';
export var draggingContainer = '_1ycp6vwd';
export var iconContainer = '_1ycp6vw7';
export var itemContent = '_1ycp6vw5';
export var itemMain = '_1ycp6vw3';
export var itemRenameAnchor = '_1ycp6vw4';
export var itemRoot = '_1ycp6vw2';
export var levelIndent = 'var(--_1ycp6vw0)';
export var linkItemRoot = '_1ycp6vw1';
export var mobileCollapsedIconContainer = '_1ycp6vwi _1ycp6vw8';
export var mobileContentContainer = '_1ycp6vwk _1ycp6vwc';
export var mobileIconContainer = '_1ycp6vwh _1ycp6vw7';
export var mobileItemContent = '_1ycp6vwj _1ycp6vw5';
export var mobileItemMain = '_1ycp6vwg _1ycp6vw3';
export var mobileItemRoot = '_1ycp6vwf _1ycp6vw2';
export var postfix = '_1ycp6vw6';