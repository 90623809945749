import "../../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/view/edit-collection/edit-collection.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE8VWy3abMBDd5yvY9Jx2oRyg1HXJ18hIgGwhEUkEuz399+qFjGL8wJtu4nikeejOnTt+3cPufXNMkz8vScI/sKgpH8ukJQhh9qZtCh8VOB9gSkkviTRHY0sUBrKHFS4TxkcB+7eXvy+vLmRmQyIiewpPZVJTfDRO+0EqUp9AxZnCTJWJ9Qc7rEbsMjawL5Ntb29DShoGdJpOlkml72NhzD1EiLCmTLJNf3R/tv6fWQX5cgXmEyAicKUIZzosp0NnM48EqVYHTdMv5muLSdOq8/dLeEKq79dTaf87zsWy810cQoAfz0IdcMxTDV9euGQ7LhAWQHGdP9N2ySlByQcUXwGAdU2YjuGuaOi4+BZANQwoE8cDX3+WRy3Z2EprXRCQ5LemTeZzWtPoAS/S1NioyTQ1Ic/dRZuyjKuxHJVYvxNBcZpVFd5XBHY887xQ/k9Xvu1qfptLPgTFtXocxYkk4DgfwWDVvYWD4rOCtlfnNgJ0YwANTr+sU8AmnUi2AtoQCy5T78rcWkrMO+5J4HsbNzx1xhuDs1uV3E93B4/TRNg+werQCD4wBJbePzvuBeliei3h7ljvqbaokBM3HqeFNwiIyCCDJkTkNvJXRKNWre9Mll62plhqTTzU6CoJV2jvXO3sLdkKwg6anrNMOGau4c15BaxM+Jg21/99izUzzclWvvHGw9rlhy1KyZQ7OJO1mzVepSHO3sbRU9UQ5qXSjX24cbA3osUczuiNs86eLU305zEOLuxiN92SpWfEkj9c1FSHOlFdCFGaQdWFqm8jVe/j2bAbL482XqQfwe/d9UA3wCO5jTsg7PkdmWwNb9wJqAmlGM3fLePSoh8bg9SOElPNGSORDH8SoAnvuAnFEz8GPAo7rhTvHl79/wDLue5aIQsAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = 'jamq6xq';
export var bottomButton = 'jamq6xp';
export var bottomLeft = 'jamq6x4';
export var collectionEditContainer = 'jamq6xi';
export var confirmButton = 'jamq6xj';
export var ellipsis = 'jamq6x0';
export var includeItem = 'jamq6xb';
export var includeItemContent = 'jamq6xa';
export var includeItemContentIs = 'jamq6x9';
export var includeItemTitle = 'jamq6x8';
export var includeListTitle = 'jamq6x6';
export var includeTitle = 'jamq6xc';
export var pageList = 'jamq6xl';
export var pagesList = 'jamq6x3';
export var pagesTab = 'jamq6x2';
export var pagesTabContent = 'jamq6x1';
export var previewActive = 'jamq6xr';
export var previewCountTips = 'jamq6xn';
export var previewCountTipsHighlight = 'jamq6xm';
export var resultPages = 'jamq6xk';
export var rulesBottom = 'jamq6x5';
export var rulesContainer = 'jamq6xh';
export var rulesContainerLeft = 'jamq6xg';
export var rulesContainerLeftContent = 'jamq6xe';
export var rulesContainerLeftContentInclude = 'jamq6xd';
export var rulesContainerLeftTab = 'jamq6xf';
export var rulesContainerRight = 'jamq6x7';
export var rulesTitle = 'jamq6xs';
export var rulesTitleHighlight = 'jamq6xo';