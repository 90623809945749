import "../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE8VW227bMAx971cIAwY0QBU4l2aZg+0/hmEYFIu2tcqSJ8lNumH/Pl3sRHbsZi0K7C1mRPLw8JDS/PtiBT+PhCbo9w1ClOmak6cU5RyOO2vIJJcqRY9E3WJM8pwJwAaOBteKVUQ9YX9g5o6WwIrSpOh+XXtXFwLrUjHxkKLEWQ6MmjJFiyR57z4JZ4XAzEClU6SNApOVzm4UEZoZJkWK9iR7KJRsBA2ZUDJf6jska5Ix8+S/nIt8BJVzeUhRySgF4aE3SjvsFHLScBMAcI6zkogC0i7G7ubPzfzEQlq6UJ6LYeoBC/7gqfw4xldKDMEZZ9kD2XP4ZFQD33zIDlItmTCgem4Lf6IjcX0fSNxLRW0aRShrtKMumC/61OMygxA9rmEaPc4tK0BnIZ1rGaGOyd75CkTT/uMPVuSI23auNi2oionOtgy2qLylL6+WXWMVcGLYI4RgqrC+HHJbOd6GaB0TnVpkY7hFkiIhhfeqCaVMFK3bNuQL3FNFiiLi/owi3UMuFYRuSEuTsL7v3u162MheS94Yn8XI2ko6QPDCtO5VGn7aCuDLLbb/ek4CkFjp634p7Wes7/DXhKqvKNAqIINSchrp8EIxy5CzDX2CF81BgHAXz8NVFr18Yi5P3RrmW0zGi4y2jRc5fNgz7Pn9awONwVy1MDu5joE+DzT6jNKcKW0sZ4zToOSe+habgdy9DyejLipg2A5cVvEhq7mXTXpB6m49RDHX41t9Isblxs7tiGDNfsFAet5e4lV/893/Q7aQwxBldnG1SUthb7NkhGe3bv4RRhs7PLMOpO+Q7V5pg2Jtu+bXwkGR2pkbbfWvgUNmum0RgdwEYY1cGM8Wq6vZ6cyhVdEmSZ6B4a/Jcx7gnNWa6TMjFmIe0z/Qx3rQyw+TwCcvaQ12x9H+Nf1slXuiYTYuhbepMqpn+zJtXlfiOBHMUhCXPygsAvTx7QAd9SSeycZEszC4ArsLtvd+ioCT/wt8ZG04i1NIrJRhfVEB+xcV8KPRhuUOQXt/+3Qg6JX+Zm+XxRbfVAL7pbsZf+5GicP67+6awaOg+341rpHRgjjhQEzb0Ydl+8K5KOIv00DyGyAMAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_13eqxadb';
export var dateCell = '_13eqxada';
export var dndCell = '_13eqxad2';
export var dragPageItemOverlay = '_13eqxad1';
export var iconCell = '_13eqxad8';
export var operationsCell = '_13eqxadc';
export var root = '_13eqxad0';
export var selectionCell = '_13eqxad4';
export var tagIndicator = '_13eqxade';
export var tagIndicatorWrapper = '_13eqxadd';
export var tagsCell = '_13eqxad9';
export var titleCell = '_13eqxad5';
export var titleCellMain = '_13eqxad6';
export var titleCellPreview = '_13eqxad7';
export var titleIconsWrapper = '_13eqxad3';