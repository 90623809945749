import {
  AFFINE_AI_PANEL_WIDGET,
  type AffineSlashMenuActionItem,
  type AffineSlashMenuContext,
  type AIItemConfig,
  type AIItemGroupConfig,
  type AffineSlashSubMenu,
  AIStarIconWithAnimation,
  BlocksUtils,
  DocModeProvider,
  MindmapElementModel,
  ShapeElementModel,
  TextElementModel,
  type AffineAIPanelWidgetConfig,
  AffineAIPanelWidget,
} from '@blocksuite/blocks';
import { assertExists } from '@blocksuite/global/utils';
import EventBus from '../../../../../eventBus';
import { getBlocksInfo } from './utils.js';
import {
  AIExpandMindMapIcon,
  AIImageIcon,
  AIImageIconWithAnimation,
  AIMindMapIcon,
  AIMindMapIconWithAnimation,
  AIPenIcon,
  AIPenIconWithAnimation,
  AIPresentationIcon,
  AIPresentationIconWithAnimation,
  AISearchIcon,
  ChatWithAIIcon,
  CommentIcon,
  ExplainIcon,
  ImproveWritingIcon,
  LanguageIcon,
  LongerIcon,
  MakeItRealIcon,
  MakeItRealIconWithAnimation,
  SelectionIcon,
  ShorterIcon,
  ToneIcon,
} from '../../_common/icons';
import {
  actionToHandler,
  actionToHandlerCritic,
  getContentFromSelected,
  imageOnlyShowWhen,
  mindmapChildShowWhen,
  mindmapRootShowWhen,
  notAllAIChatBlockShowWhen,
  noteBlockOrTextShowWhen,
  noteWithCodeBlockShowWen,
  notGeneratedShowWhen,
} from '../../actions/edgeless-handler';
import {
  imageFilterStyles,
  imageProcessingTypes,
  textTones,
  translateLangs,
} from '../../actions/types';
import { getAIPanel } from '../../ai-panel';
import { AIProvider } from '../../provider';
import {
  getEdgelessCopilotWidget,
  mindMapToMarkdown,
} from '../../utils/edgeless';
import { canvasToBlob, randomSeed } from '../../utils/image';
import {
  getCopilotSelectedElems,
  getEdgelessRootFromEditor,
  getSelections,
  imageCustomInput,
  selectAboveBlocks,
} from '../../utils/selection-utils';
import type { EditorHost } from '@blocksuite/block-std';
import axios from 'axios';
import { getElementToolbar } from '../../actions/edgeless-response';

const translateSubItem = translateLangs.map(lang => {
  return {
    type: lang,
    handler: actionToHandler('translate', AIStarIconWithAnimation, { lang }),
  };
});

const toneSubItem = textTones.map(tone => {
  return {
    type: tone,
    handler: actionToHandler('changeTone', AIStarIconWithAnimation, { tone }),
  };
});

export const imageFilterSubItem = imageFilterStyles.map(style => {
  return {
    type: style,
    handler: actionToHandler(
      'filterImage',
      AIImageIconWithAnimation,
      {
        style,
      },
      imageCustomInput
    ),
  };
});

export const imageProcessingSubItem = imageProcessingTypes.map(type => {
  return {
    type,
    handler: actionToHandler(
      'processImage',
      AIImageIconWithAnimation,
      {
        type,
      },
      imageCustomInput
    ),
  };
});

const othersGroup: AIItemGroupConfig = {
  name: 'others',
  items: [
    {
      name: 'Continue with AI',
      icon: CommentIcon,
      showWhen: () => true,
      handler: host => {
        const panel = getAIPanel(host);
        AIProvider.slots.requestOpenWithChat.emit({
          host,
          mode: 'edgeless',
          autoSelect: true,
        });
        panel.hide();
      },
    },
    {
      name: '打开 AI Chat',
      icon: ChatWithAIIcon,
      showWhen: () => true,
      handler: host => {
        const panel = getAIPanel(host);
        AIProvider.slots.requestOpenWithChat.emit({ host, mode: 'edgeless' });
        panel.hide();
      },
    },
  ],
};

const editGroup: AIItemGroupConfig = {
  name: '使用AI编辑',
  items: [
    {
      name: 'Translate to',
      icon: LanguageIcon,
      showWhen: noteBlockOrTextShowWhen,
      subItem: translateSubItem,
    },
    {
      name: 'Change tone to',
      icon: ToneIcon,
      showWhen: noteBlockOrTextShowWhen,
      subItem: toneSubItem,
    },
    {
      name: '改进写作',
      icon: ImproveWritingIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('improveWriting', AIStarIconWithAnimation),
    },

    {
      name: '扩充文案',
      icon: LongerIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('makeLonger', AIStarIconWithAnimation),
    },
    {
      name: '精简文案',
      icon: ShorterIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('makeShorter', AIStarIconWithAnimation),
    },
    {
      name: 'AI续写',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('continueWriting', AIPenIconWithAnimation),
    },
  ],
};

const draftGroup: AIItemGroupConfig = {
  name: 'draft with ai',
  items: [
    {
      name: '写一篇关于这个的文章',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('writeArticle', AIPenIconWithAnimation),
    },
    {
      name: '写一条关于这个的Twitter',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('writeTwitterPost', AIPenIconWithAnimation),
    },
    {
      name: '写一首关于这个的诗',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('writePoem', AIPenIconWithAnimation),
    },
    {
      name: '写一篇关于这个的博客',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('writeBlogPost', AIPenIconWithAnimation),
    },
    {
      name: '写一些关于这个的头脑风暴',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('brainstorm', AIPenIconWithAnimation),
    },
  ],
};

async function handleInlineAskGenerateAction(host: EditorHost) {
  const panel = getAIPanel(host);
  const edgelessCopilot = getEdgelessCopilotWidget(host);
  const selectedElements = getCopilotSelectedElems(host);
  const { selectedBlocks } = getSelections(host);
  const { notes, shapes, images, edgelessTexts, embedSyncedDocs } =
    BlocksUtils.splitElements(selectedElements);
  const blocks = [
    ...notes,
    ...shapes,
    ...images,
    ...edgelessTexts,
    ...embedSyncedDocs,
  ];
  if (blocks.length === 0) return true;
  let content = await getContentFromSelected(host, blocks);

  edgelessCopilot.hideCopilotPanel();
  edgelessCopilot.lockToolbar(true);

  const generateAnswer: AffineAIPanelWidgetConfig['generateAnswer'] = async ({
    finish,
    input,
    signal,
    update,
  }) => {
    if (!AIProvider.actions.chat) return;

    // recover selection to get content from above blocks
    // assertExists(selection);
    // host.selection.set([selection]);
    // 调用 Python 后端 API
    const response = await axios.get(
      '/back_api/llm-services/critic_text',
      {
        params: { query: input, context: content },
      }
    );

    // console.log(panel.childNodes());
    // let btn = panel.getElementsByClassName('ai-item-insert-below');

    // 假设 API 返回的数据在 response.data 中
    const result = response.data;
    // 立即更新并完成
    update(result);
    finish('success');
  };
  const elementToolbar = getElementToolbar(host);
  let referenceElement;
  if (selectedBlocks && selectedBlocks.length !== 0) {
    referenceElement = selectedBlocks.at(-1);
  } else if (edgelessCopilot.visible && edgelessCopilot.selectionElem) {
    referenceElement = edgelessCopilot.selectionElem;
  } else if (elementToolbar.toolbarVisible) {
    referenceElement = getElementToolbar(host);
  }

  if (!referenceElement) return;

  assertExists(panel.config);
  panel.config.generateAnswer = generateAnswer;
  panel.toggle(referenceElement);
}

async function handleGenerateAction(host: EditorHost) {
  const panel = getAIPanel(host);
  const edgelessCopilot = getEdgelessCopilotWidget(host);
  const selectedElements = getCopilotSelectedElems(host);
  const { notes, shapes, images, edgelessTexts, embedSyncedDocs } =
    BlocksUtils.splitElements(selectedElements);
  const blocks = [
    ...notes,
    ...shapes,
    ...images,
    ...edgelessTexts,
    ...embedSyncedDocs,
  ];
  if (blocks.length === 0) return true;
  // let content = await getContentFromSelected(host, blocks);
  let containerId = selectedElements?.[0]?.id;
  let bolckDetail;

  try {
    bolckDetail = JSON.parse(localStorage.getItem(containerId));
  } catch (error) {
    bolckDetail = localStorage.getItem(containerId);
  }
  let blocksInfo = await getBlocksInfo(selectedElements?.[0]?.children, host);

  let context = {
    [containerId]: {
      blocks: blocksInfo,
      detail: bolckDetail || {},
    },
  };
  edgelessCopilot.hideCopilotPanel();

  let positions = Object.values(host?.doc.blocks.value)
    .filter(item => item.flavour == 'affine:note')
    .map(item => {
      const blockPosition = [
        item.model.elementBound.x,
        item.model.elementBound.y,
      ];
      return { [item.id]: blockPosition };
    });

  let message = {
    requirement: blocksInfo
      ?.filter(item => item?.block_type === 'affine:paragraph')
      .map(item => item.block_content)
      .join('\n'),
    msg_type: 'critic',
    agent_action_name: 'LLM_EXTRACT_KEY_WORDS',
    all_block_positions: positions,
    context: {
      this_container: context,
    },
  };
  EventBus.emit('generate', message);
  // const generateAnswer: AffineAIPanelWidgetConfig['generateAnswer'] = async ({
  //   finish,
  //   input,
  //   signal,
  //   update,
  // }) => {
  //   if (!AIProvider.actions.chat) return;

  //   // recover selection to get content from above blocks
  //   // assertExists(selection);
  //   // host.selection.set([selection]);

  //   const fullInput = `${input}${content}`;

  //   // 调用 Python 后端 API
  //   const response = await axios.get('http://10.0.12.6:9099/api/translate', {
  //     params: { query: fullInput },
  //   });

  //   // console.log(panel.childNodes());
  //   // let btn = panel.getElementsByClassName('ai-item-insert-below');

  //   // 假设 API 返回的数据在 response.data 中
  //   const result = response.data;
  //   // 立即更新并完成
  //   update(result);
  //   finish('success');
  // };

  // const elementToolbar = getElementToolbar(host);
  // let referenceElement;
  // if (selectedBlocks && selectedBlocks.length !== 0) {
  //   referenceElement = selectedBlocks.at(-1);
  // } else if (edgelessCopilot.visible && edgelessCopilot.selectionElem) {
  //   referenceElement = edgelessCopilot.selectionElem;
  // } else if (elementToolbar.toolbarVisible) {
  //   referenceElement = getElementToolbar(host);
  // }

  // if (!referenceElement) return;

  // assertExists(panel.config);
  // panel.config.generateAnswer = generateAnswer;
  // panel.toggle(referenceElement);
}

const reviewGroup: AIItemGroupConfig = {
  name: 'Noumena-AI 帮您审核',
  items: [
    // {
    //   name: 'Generate With Noumena',
    //   icon: AIPenIcon,
    //   showWhen: notGeneratedShowWhen,
    //   handler: context => {
    //     const rootComponent = getEdgelessRootFromEditor(context);
    //     const view = rootComponent.host.view;
    //     const affineAIPanelWidget = view.getWidget(
    //       AFFINE_AI_PANEL_WIDGET,
    //       rootComponent.model.id
    //     ) as AffineAIPanelWidget;
    //     assertExists(affineAIPanelWidget);
    //     assertExists(affineAIPanelWidget.host);
    //     handleGenerateAction(affineAIPanelWidget.host);
    //   },
    // },
    {
      name: 'Review With Noumena',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandlerCritic('summary', AIStarIconWithAnimation),
      // handler: context => {
      //   const rootComponent = getEdgelessRootFromEditor(context);
      //   const view = rootComponent.host.view;
      //   const affineAIPanelWidget = view.getWidget(
      //     AFFINE_AI_PANEL_WIDGET,
      //     rootComponent.model.id
      //   ) as AffineAIPanelWidget;
      //   assertExists(affineAIPanelWidget);
      //   assertExists(affineAIPanelWidget.host);
      //   handleInlineAskGenerateAction(affineAIPanelWidget.host);
      // },
    },
    {
      name: 'Fix spelling',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('fixSpelling', AIStarIconWithAnimation),
    },
    {
      name: '改进语法',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('improveGrammar', AIStarIconWithAnimation),
    },
    {
      name: '解释图片',
      icon: AIPenIcon,
      showWhen: imageOnlyShowWhen,
      handler: actionToHandler(
        'explainImage',
        AIStarIconWithAnimation,
        undefined,
        imageCustomInput
      ),
    },
    {
      name: '解释代码',
      icon: ExplainIcon,
      showWhen: noteWithCodeBlockShowWen,
      handler: actionToHandler('explainCode', AIStarIconWithAnimation),
    },
    {
      name: '检查代码错误',
      icon: ExplainIcon,
      showWhen: noteWithCodeBlockShowWen,
      handler: actionToHandler('checkCodeErrors', AIStarIconWithAnimation),
    },
    {
      name: '解释选中的区域内容',
      icon: SelectionIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('explain', AIStarIconWithAnimation),
    },
  ],
};

const generateGroup: AIItemGroupConfig = {
  name: '用 Noumena-AI 完善您的作品',
  items: [
    {
      name: '总结内容',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('summary', AIPenIconWithAnimation),
    },
    {
      name: '生成标题',
      icon: AIPenIcon,
      handler: actionToHandler('createHeadings', AIPenIconWithAnimation),
      showWhen: noteBlockOrTextShowWhen,
      beta: true,
    },
    {
      name: '生成图片',
      icon: AIImageIcon,
      showWhen: notAllAIChatBlockShowWhen,
      handler: actionToHandler(
        'createImage',
        AIImageIconWithAnimation,
        undefined,
        async (host, ctx) => {
          const selectedElements = getCopilotSelectedElems(host);
          const len = selectedElements.length;

          const aiPanel = getAIPanel(host);
          // text to image
          // from user input
          if (len === 0) {
            const content = aiPanel.inputText?.trim();
            if (!content) return;
            return {
              content,
            };
          }

          let content = (ctx.get()['content'] as string) || '';

          // from user input
          if (content.length === 0) {
            content = aiPanel.inputText?.trim() || '';
          }

          const {
            images,
            shapes,
            notes: _,
            frames: __,
          } = BlocksUtils.splitElements(selectedElements);

          const pureShapes = shapes.filter(
            e =>
              !(
                e instanceof TextElementModel ||
                (e instanceof ShapeElementModel && e.text?.length)
              )
          );

          // text to image
          if (content.length && images.length + pureShapes.length === 0) {
            return {
              content,
            };
          }

          // image to image
          const edgelessRoot = getEdgelessRootFromEditor(host);
          const canvas = await edgelessRoot.clipboardController.toCanvas(
            images,
            pureShapes,
            {
              dpr: 1,
              padding: 0,
              background: 'white',
            }
          );
          if (!canvas) return;

          const png = await canvasToBlob(canvas);
          if (!png) return;
          return {
            content,
            attachments: [png],
            seed: String(randomSeed()),
          };
        }
      ),
    },
    {
      name: '生成大纲',
      icon: AIPenIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('writeOutline', AIPenIconWithAnimation),
    },
    {
      name: 'Expand from this mind map node',
      icon: AIExpandMindMapIcon,
      showWhen: mindmapChildShowWhen,
      handler: actionToHandler(
        'expandMindmap',
        AIMindMapIconWithAnimation,
        undefined,
        function (host) {
          const selected = getCopilotSelectedElems(host);
          const firstSelected = selected[0] as ShapeElementModel;
          const mindmap = firstSelected?.group;

          if (!(mindmap instanceof MindmapElementModel)) {
            return Promise.resolve({});
          }

          return Promise.resolve({
            input: firstSelected.text?.toString() ?? '',
            mindmap: mindMapToMarkdown(mindmap),
          });
        }
      ),
      beta: true,
    },
    {
      name: '头脑风暴思维导图',
      icon: AIMindMapIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('brainstormMindmap', AIMindMapIconWithAnimation),
    },
    {
      name: '重新生成思维导图',
      icon: AIMindMapIcon,
      showWhen: mindmapRootShowWhen,
      handler: actionToHandler(
        'brainstormMindmap',
        AIMindMapIconWithAnimation,
        {
          regenerate: true,
        }
      ),
    },
    {
      name: '生成 presentations',
      icon: AIPresentationIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('createSlides', AIPresentationIconWithAnimation),
      beta: true,
    },
    {
      name: '变得更真实',
      icon: MakeItRealIcon,
      beta: true,
      showWhen: notAllAIChatBlockShowWhen,
      handler: actionToHandler(
        'makeItReal',
        MakeItRealIconWithAnimation,
        undefined,
        async (host, ctx) => {
          const selectedElements = getCopilotSelectedElems(host);

          // from user input
          if (selectedElements.length === 0) {
            const aiPanel = getAIPanel(host);
            const content = aiPanel.inputText?.trim();
            if (!content) return;
            return {
              content,
            };
          }

          const { notes, frames, shapes, images, edgelessTexts } =
            BlocksUtils.splitElements(selectedElements);
          const f = frames.length;
          const i = images.length;
          const n = notes.length;
          const s = shapes.length;
          const e = edgelessTexts.length;

          if (f + i + n + s + e === 0) {
            return;
          }
          let content = (ctx.get()['content'] as string) || '';

          // single note, text
          if (
            i === 0 &&
            n + s + e === 1 &&
            (n === 1 ||
              e === 1 ||
              (s === 1 && shapes[0] instanceof TextElementModel))
          ) {
            return {
              content,
            };
          }

          // from user input
          if (content.length === 0) {
            const aiPanel = getAIPanel(host);
            content = aiPanel.inputText?.trim() || '';
          }

          const edgelessRoot = getEdgelessRootFromEditor(host);
          const canvas = await edgelessRoot.clipboardController.toCanvas(
            [...notes, ...frames, ...images],
            shapes,
            {
              dpr: 1,
              background: 'white',
            }
          );
          if (!canvas) return;
          const png = await canvasToBlob(canvas);
          if (!png) return;
          ctx.set({
            width: canvas.width,
            height: canvas.height,
          });

          return {
            content,
            attachments: [png],
          };
        }
      ),
    },
    {
      name: 'AI 图片过滤',
      icon: ImproveWritingIcon,
      showWhen: imageOnlyShowWhen,
      subItem: imageFilterSubItem,
      subItemOffset: [12, -4],
      beta: true,
    },
    {
      name: '图片处理',
      icon: AIImageIcon,
      showWhen: imageOnlyShowWhen,
      subItem: imageProcessingSubItem,
      subItemOffset: [12, -6],
      beta: true,
    },
    {
      name: '生成图片描述',
      icon: AIPenIcon,
      showWhen: imageOnlyShowWhen,
      beta: true,
      handler: actionToHandler(
        'generateCaption',
        AIStarIconWithAnimation,
        undefined,
        imageCustomInput
      ),
    },
    {
      name: 'Find actions',
      icon: AISearchIcon,
      showWhen: noteBlockOrTextShowWhen,
      handler: actionToHandler('findActions', AIStarIconWithAnimation),
      beta: true,
    },
  ],
};

export const edgelessActionGroups = [
  reviewGroup,
  editGroup,
  generateGroup,
  draftGroup,
  othersGroup,
];
