import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71W227bMAx971cIAwY0wBQ412UOtv8YhmFQLNrWKkuGJDfphv77dLET2XHSZiv2ZtMieXh4RHr6Y1Yty0YWCfp9hxBluubkKUU5h8PWGjLJpUrRI1H3GJM8ZwKwgYPBtWIVUU/YH5i4oyWwojQpWi1r7+pCYF0qJh5SlDjLnlFTpmiWJO/dK+GsEJgZqHSKtFFgstLZjSJCM8OkSNGOZA+Fko2gIRNKpnP9AcmaZMw8+TfnIh9B5VzuU1QySkF46I3SDjuFnDTcbO+e76bHatPSufiahykG1fqDxzLjGN8oMQRnnGUPZMfhs1ENfPchu9S1ZMKA6rnN/ImOrOUqkLWTito0ilDWWDY2wXrWjh5lGYTgcQmXweOccQ50ErK5zhDqCOudr0A07Rd/sCIH3HZtsU4CqIqJzjYPtqi6ua+ull3/FHBi2COEYKqwvhxyWzhuS+yI6EQhG8MtkhQJKbxXTShlomjdNiFfoJ4qUhQR9ScU6Q5yqSA0Q1qahPV9927bw0Z2WvLG+CxG1la5AYLXn3Wv0vBoK4Cv99h+9ZwEILGgl/1S2tdYxuHTBfG+IECrgAxKyWkkwzPBzEPONrSH9wqWvDxiro7dGMabXYwXGW2bznL4sCdY09XfBhqDuWhhdnIcA326r+gLSnOmtMFZyTgNSu2pa7YeyNn7cDLqogKGzcBlER+yFaPVbXe5ILW7DYOoy/HxfCHG+ejN7SXAmv2Cgbi8vcSL/mhbjWfzYSlTkAVVWzU2lTjDEbIbosy2z0SgtzdVMsKze3f3EUZre3EmXR7fPdvZ0gbF2nbUj4S9IrUzN9pqXwO3SLpJEcFfB9GN7ISrNOhqcjyzbxW2TpIrMCKgfimeUgLnrNZMn8ixaPNozUVwP16Ee3H7arBTjfb379XaDvqVzP5DHZvbNPqyIscJYLb0uOyB1iNAn94OUMvfTQ2JlD9Ydt0q7f0QRcDJ/wN+/h83MjycxQkkFsp5ddcv5e6mmn422rDcwWqXt8cAgr7Q8uztsoTphv08Xo//0j7/Ac9NNFY/CwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';