import "../../core/src/components/affine/setting-modal/general-setting/experimental-features/index.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/experimental-features/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE6VUwVKDMBC99ytycUYPcWjFWunHOIEssBoSJgkt6vjvJkEFYnHa8QLD7vJ239uX3D7t0i23fULeV4S0yqBFJTPCcqNEZ2HvohxNK9hrRkoBvQ/4N+WooRiKC1faSJ+pAavaZmSdJFf71cfq9gt+HeBLJS01+AYZOTB9TSkrS5RAQ7ym6c3+u+j4hbNNEh9rmK5Q0lxZq5qMpLu2n6JvAnpUtI2K7kJRzoqXSqtOcuqmVjqaZJK2oC0y/TrUjaMtzd+bmwvFeu6MxdI3kBakY1u4J2ifqljrREzbgNAyzlFWXtUhkCvNQVPNOHYmIxHRNBA9xQ60VjrmM5N6RLkfNuaHd4IcXfOwCCfwz443877b8Mcv/kxgJSlaaMyU4R/ko0XebQbWQZN03vPhdM9lzQPIJkLZnUZZHHH88/F/nE3LCmc6sEeAMN4Rua1/Hx82Hp/oZJxas4Xe2RRcFz73byRs5Jv8Ui0XWA7eTebgxaIpF6c957SpA+hSeHvWyPmgYQAcEyAEtgbN7HA6GfMXtE6KwPH7U3j8QrDGm2SacHVUaQxLO/iLoWDij33xwHaWPN8l5/C+SMiwkGjZML0yBZSOF+usmrhEDz7zl8InNci62iUGAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRightIcon = '_846dtxe';
export var description = '_846dtxc';
export var feedback = '_846dtxd';
export var promptArt = '_846dtx2';
export var promptDisclaimer = '_846dtx6';
export var promptDisclaimerConfirm = '_846dtx8';
export var promptRoot = '_846dtx0';
export var promptTitle = '_846dtx1';
export var promptWarning = '_846dtx3';
export var promptWarningTitle = '_846dtx4';
export var rowContainer = '_846dtxb';
export var settingsContainer = '_846dtx7';
export var spacer = '_846dtx5';
export var subHeader = '_846dtxa';
export var switchRow = '_846dtx9';