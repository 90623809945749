import { useEffect } from 'react';
import EventBus from '../index.js';

function useEventBus(event, callback) {
  useEffect(() => {
    EventBus.on(event, callback);
    return () => {
      EventBus.off(event, callback);
    };
  }, [event, callback]);
}

export default useEventBus;
