import "../../component/src/ui/avatar/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/avatar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VW23LqIBR971fw0hmdKT25mVtf+iuYEEOLJANEa8/47wfIjURP2jp1Ro0EWGuvvTY7eX3Hp4KjPRbgTYSCZB74+wCA82guAMiqTgEMgscXM6S4kGrsut1YcsREUfF9CvZIcvKxgs6zlzwBdUnCJ6B+AzPwN2qgPmu976y+bthT9Jhxj2koN4sUkW9Qw0RThC1FFM0ofG9GEU0YAmeJIYkMqOdrBjdsFSUzhiCei9hMKPxkiSKONXYQt1LMbzwjCIN7S4idu/vgOvcup/PD67yO/Wkdd0y+U39MDBrH16g7b1qjBp+W69iPZhTRIkXc+rLxNIXfSfW+qmRvpsNd0uEaUMczF9f7Zil7Mx3esowbavn3RVwU8z3MsMr5TlV1fnhuizgwPEeSyzI1vHq+xGRXynFcV4JIUrEUcEyRJAes71YHzAtaHVNQkjzH7GVE3diosT8Bjd0ZJtqKijbSYA5yzZpeazu4QWhVo4zIU6on9bggVGKegi1t+OqA+ArCNmB3vZ5wwIqTHVHBZZipHd1FL0GMKHoTeN8GfAEwEhgSBqtGArX/iHguAGEFYaQVNmyDNUUnKCSSOAU1agTOrcSFJnHXF/OGMcJ21urITnMST9KcRAtpnjTJaYu8rUHeIdFHQinMSsR2SruO8UmH/TRuveqG95tuxD9yI5kepWBqR7BgB2/XXC37mzM78S+2/WufqL79EP3Sj0ElMiqvd4ScCJ2iVOWaEoZhQbHpUvoKRckJe1c6LLCtAesVOClomMDyZUyjLbCNtE/o5UxRMQkF+VTmZIhmq+kK8Ad460mQfXSIkh2DqjL2IrXq760RkhQnmClYddOeUo1PqkxQaLaqRKv+R00GVAFxKDDFmdrAKoZvap/Zd5pytX1TLLAgOjQNZgHk3wK4COBXkrNF2fuOVw3L1SyteG8VKgpdEzVXp4mf2jljyLVVx1JRmllTSEPog8/H7s7GsesJ/+xpZp/BC+ULEv+TlEvlfLdFq1CdslC9XITmLXljVH2q9pTjj07R1Tw13E7D2AaG49qJ6GbAs7sRBqzhQqPVFZkdXZyW2nOTowHPtRYUs7N9rUfF7YvH5I3tQATZEmrwxlqyNY7to4thwmjvN//1YTr/AxStj/s5DgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var DefaultAvatarBottomItemStyle = 'js6sic7';
export var DefaultAvatarBottomItemWithAnimationStyle = 'js6sic8';
export var DefaultAvatarContainerStyle = 'js6sic4';
export var DefaultAvatarMiddleItemStyle = 'js6sic5';
export var DefaultAvatarMiddleItemWithAnimationStyle = 'js6sic6';
export var DefaultAvatarTopItemStyle = 'js6sic9';
export var avatarFallback = 'js6sicd';
export var avatarImage = 'js6sicc';
export var avatarRoot = 'js6sica';
export var avatarWrapper = 'js6sicb';
export var blurVar = 'var(--js6sic1)';
export var hoverWrapper = 'js6sice';
export var removeButton = 'js6sicf';
export var sizeVar = 'var(--js6sic0)';