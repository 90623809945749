import "../../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VX227jIBB9369AlVZKpBKRNMm27mfsB6ywwTatjS1Mbq367zuAHWOHJL0+tJVhODNz5sxAZ/9W2bOmTwS9/kKIiaYu6CFCacH3j7BAC5FJLDQvG7eIuWRmo6QqExLrqo7QfFGD8duvWYs1t1jWOlPVDgwer2InXGquPOSCpzpCCzKEXljonWA6B1hCfn8C2ca8WNbWNKMmAeI+bMg7ZZbMb9/xne94tXb2ORdZrvvvuFKMK6woExvwvHLh1VUjtKhkhBQvqBZbblaTjWoqFcGu6OKz7ptcCfkcITLwPoMcaVxwG0V3lPGUbgrtfFgUzLeQLbiWleQDgCivtlyhWUJLrigWSSVtpjUsBir/LSfPn/kijzRuqmKj+aD4LmWEnjaNFukBg08NZPgCOKOLmCbPINSNZHCoMMyqLKaTNblF6zn83N0iMltNjekLFpLxfSvp1npL1QRjmqZCcrzLAd/aphAAbsQL79TWU7P0WUhokUyMlhFGazCEP/NVvZ8GkzFtYTY032ts8+nWfPSmpjLUKaaSaWFaMheMcXlE6jd4UYi6EY3ZsrlgAEu44df1RDhtqEHC86owdbP7PQW7rrpkoOmVDTDcHBW4FBqKSmbLcOd6OFFEU90K68jTzc3jafqdzrrvsKLc5CGWG+OQ+O0qK0M7MMWZH8Q6PD7fq0Vj/1dTpc/Ra4vUcMBhVB08hu38amdZTRkTMovQfTvOegEOwOz6vpn6Cfxx1egQlsFGdIPeb5gRstdJtRLlMFQHBiggcWBbsNFZ56u3P2HTzkcmFE9c0cB0U8ogz1azOOZ6x43K+zwfhnkSkyn8no8nUUvhSQwjRQUniuetHZ6vl0izJic8HUm/H8r9IQIJTqKCNtC1KdaHmk8tftsjcaV1VUZoPTxG/XGwWJEz6Z2neHw5jS7qThrtonI0jq/vOPgy+J6pdFHtTTlQezyr4R0DCmiHRmCejeTbn01cDle9XWjk0974HgoKK6dWwovRq4xdj9vOhA8OoJ+InIwl3z5B2m/3CjreEfPQAwhmtA+R/kzyo55bDePO3vVYPXl0fuoJM5wZ69GFAFI4PnKvjGFXoDsyfsJ8nLBTlQeqfEYRPYn51yvHyzqnoD8vksDrezDOlqP5KKSJ3Y9LDG98Z4Av/gfSH34aJ9XdP1/K4e0/TbazM80NAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRight = '_5gktajj';
export var avatarWrapper = '_5gktaj3';
export var fakeWrapper = '_5gktaj5';
export var goUpgrade = '_5gktajh';
export var goUpgradeWrapper = '_5gktaji';
export var iconButton = '_5gktaje';
export var label = '_5gktajf';
export var labelWrapper = '_5gktaj2';
export var memberContainer = '_5gktaja';
export var memberEmail = '_5gktajd';
export var memberList = '_5gktaj8';
export var memberListItem = '_5gktaj9';
export var memberName = '_5gktajc';
export var membersFallback = '_5gktaj6';
export var membersPanel = '_5gktaj7';
export var profileHandlerWrapper = '_5gktaj1';
export var profileWrapper = '_5gktaj0';
export var roleOrStatus = '_5gktajb';
export var urlButton = '_5gktaj4';
export var workspaceLabel = '_5gktajg';