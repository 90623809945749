export default class Socket {
    constructor(url, data, callback) {
      this.url = url
      this.data = data
      this.callback = callback
      this.websock = new WebSocket(this.url)
      this.initSocket()
    }
    initSocket() {
      const _this = this
      if (this.websock.readyState === this.websock.OPEN) {
        // 若是ws开启状态
        this.websock.send(_this.data, _this.callback)
      } else {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function() {
          _this.initSocket(this.url)
        }, 1000)
        return
      }
      // socket接收消息事件
      this.websock.onmessage = function(e) {
        _this.callback(JSON.parse(e.data))
      }
      // socket关闭事件
      this.websock.onclose = function(e) {
        _this.websock.close()
        // console.log('WebSocket关闭')
      }
      // socket链接成功事件
      this.websock.onopen = function() {
        // console.log('WebSocket连接成功')
      }
      // socket关闭事件连接发生错误的回调方法
      this.websock.onerror = function() {
        // console.log('WebSocket连接发生错误')
      }
    }
    send(data, callback) {
      // vue里面要是销毁就传一个空的箭头函数()=>{}
      this.callback = callback
      this.websock.send(data)
    }
    close() {
      this.websock.close()
    }
  }
