import "../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61V246bMBB9369wHyrtSnUE5Fr60j+pDHaCu2Aj22zYrfLvHRsTDIHstlolSvAwHp8zc2a8+hU/H+S62EfozwNCBeOnwqQojqKvP2BNua5L8pqiY8laayAlPwnMDat0inImDFPW/LvRhh9fcS7BIszw6vKw6k+I3Qm11NxwKVJEMi3LxjC7nwvNYFdkn8+cmmKAMIYUxEvuxLvBbf8x5YrlnXMOrpW4C32RbI9ovavbAHC/zKSiTGFFKG+0xd2ZjayB4GqrWGWXqosxGDKSP5+UbARN0QtRjxiT45ELhs8FIHgaIqdISOFY1oRSLk5hlLxRWoJLLXkPF6hayygmB7bYvXCBjSKiT+OAA8ImGjGiwV9g2Rjr+gbPlLXAy64qok7wznFbHzqi3uYJdtagbGtXtjlQA9H5oh6hQljzN5ainU9qaff19eiNzu/sjZksqTXKmuTcvNpcJePcoXjb7bvJXYB641DfyGraL9fsJLPS0gCC4YyZM2NiUWHBsdv5Y5fVvCDZsGpB+N0nhW80CF6zEjb06gxO2X+g4qGQks1EMofRbOr7rCIt9q1nH32SJ7X1vjcU/73rTwSgHWZbfBNaXTfWLQLdcjqm67cNfTf0Gr6fnky2WBeEyjOEh+BAC8X2xy7UKSOP0Tfkv6t4M+zhby4RniYgaD84Eu6X9Pu8cP5/liY+hxWIoJ+n3nStZgxck6DFu1EQT9VCHDYfxNVirlMXx+1NkT7zDsxGSk7uMAwSsY0WSL9bSPSFV7VUhti+CGGkhXxhyoF5R4PO8ZqNIEb+SbNjmPVH3jLaKdcYWUF+fLuFUzVAQB2CYAzsDrvltATNFJTe9477rPbbp3E5otGNdoUVTxTHHJDhhpleqf4Nmt6ml4efFaOcIJ0ruBAQgTv3MeCz30ECnlzwyY2wmM1L6Lv3voa1BrsNY9fLXxp+nLr+CQAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var captionStyle = '_1k8o3h7d';
export var cursorStyle = '_1k8o3h79';
export var dividerStyle = '_1k8o3h7a';
export var imageBottomContainerStyle = '_1k8o3h7c';
export var imageNavigationControlStyle = '_1k8o3h74';
export var imagePreviewActionBarStyle = '_1k8o3h78';
export var imagePreviewModalCaptionStyle = '_1k8o3h77';
export var imagePreviewModalCenterStyle = '_1k8o3h76';
export var imagePreviewModalCloseButtonStyle = '_1k8o3h72';
export var imagePreviewModalContainerStyle = '_1k8o3h75';
export var imagePreviewModalGoStyle = '_1k8o3h73';
export var imagePreviewModalStyle = '_1k8o3h70';
export var imagePreviewTrap = '_1k8o3h71';
export var scaleIndicatorButtonStyle = '_1k8o3h7b';
export var suspenseFallbackStyle = '_1k8o3h7e';