import "../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-header.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VWwW6jMBC99yt8qbQ9OAKapCn5mJWDDUwLBtkQ0l3tv+/YEDCEkKRSL5Ey2G9mnt88e/Xb/8z9U6M88veJkFRAklYh8T2vPO0xwDJIJIVK5DokkZCVUCZcMs5BJiFZ78oT8bf4E3jmZ91uK45CxVnRhCQFzoU0MQ66zNhXSOJM2EUfta4g/qJRgbASs+qSRYIeRNWIdsuBRZ+JKmrJQ3Jk6helLI5B4pr+Ay0V5EwZlKxQL/unf0+rc0++7SlGeKrhj5hg2HhKNy/786Km637jeSZmESebKnFCMIElczfpTHdXmEtYGZJdy9KZ7aD7X2uhEDwTEQZlIcWom8B2M1cUYDlzpYDMbKNLFTkJXq8msF0vEL22O++l4Pa59/LyUVMdOXfXdePET9quORSKI9uKcai1EXyfpc4ltafUabld2daiiwz4RIot0JD9O6IlJGcn2gCv0pC8esfGhGx3wySJLINSgzafmhRZpZY3o5RGsXIkqKAt3R5/P9Or7ZvIbZO10obLsoALEWzsUXaVTHS6cwnpqdt4z5Z0PHSqUwXyMyTeCHNrMec84dEWHdC3e8fBUYNveXFAdt8ACbwJyPu8+i0hHBROMxQy7KS1H9hFk30eqX03AWYWuP/sOXuD9+01p3UADvOVuR5kqzTkujrqUxpj9zsx5SB7La2DhevBKSBqHdjkQOVjkf54vM2d4bXyVwkgSXQIneeuNULstK6MnofA4pjrfDi3mOWQWT9MhYLKcRMn4kxn97FSTKL+FLZ1k+koDJHjSKRFhkVfVZWzZs5H+aNKMq7Rn8n5zh4KZXVVjBKI0STSkzuLZaGhxVciYxUcxTRDcM4wCMjBju8rHmVw18Oie1e4/vMaTNPfJ4ILv7vwsPWDd4zbdpgaPm3zlxoaQdmF8xhHQPIF//YNHK+QeHbIxGI1tdSichm5EEjyA/af/oT9w4K1+UvuNPP+mDGuj9tPx+4h8dDL8eIE/wOqxpwfgAsAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowDownSmallIcon = '_1km1xwr7';
export var buttonText = '_1km1xwrj';
export var docListHeader = '_1km1xwr0';
export var docListHeaderTitle = '_1km1xwr1';
export var rightButtonGroup = '_1km1xwri';
export var searchIcon = '_1km1xwr8';
export var searchInput = '_1km1xwrc';
export var tagIcon = '_1km1xwrg';
export var tagIndicator = '_1km1xwr5';
export var tagLabel = '_1km1xwr6';
export var tagSelectorItem = '_1km1xwrf';
export var tagSelectorItemText = '_1km1xwrh';
export var tagSelectorTagsScrollContainer = '_1km1xwre';
export var tagSticky = '_1km1xwr4';
export var tagsEditorRoot = '_1km1xwr9';
export var tagsEditorSelectedTags = '_1km1xwrb';
export var tagsEditorTagsSelector = '_1km1xwrd';
export var tagsMenu = '_1km1xwra';
export var titleCollectionName = '_1km1xwr3';
export var titleIcon = '_1km1xwr2';