import type { EditorHost } from '@blocksuite/block-std';

import { on, stopPropagation } from '@blocksuite/affine-shared/utils';
import { WithDisposable } from '@blocksuite/affine/global/utils';
import { LitElement, css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { AIItemGroupConfig } from '@blocksuite/blocks';
import type { EdgelessRootBlockComponent } from '@blocksuite/blocks';

import { scrollbarStyle } from '@blocksuite/blocks';
import * as Y from 'yjs';
import axios from 'axios';

@customElement('edgeless-copilot-panel-custom')
export class EdgelessCopilotPanel extends WithDisposable(LitElement) {
  static override styles = css`
    :host {
      display: flex;
      position: absolute;
    }

    .edgeless-copilot-panel {
      white-space: break-spaces;

      box-sizing: border-box;
      padding: 8px 4px 8px 8px;
      min-width: 330px;
      max-height: 774px;
      overflow-y: auto;
      background: var(--affine-background-overlay-panel-color);
      box-shadow: var(--affine-shadow-2);
      border-radius: 8px;
      z-index: var(--affine-z-index-popover);
      display: flex;
      .panel-left {
        position: relative;
        width: 400px;
        height: 580;
        /* padding: 8px; */
        /* background: #adf; */
        display: flex;
        flex-direction: column;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          flex: 1;
        }
        .regenerate {
          padding: 4px 0;
          width: 100%;
          input {
            margin-bottom: 4px;
          }
        }
        .header {
        }
        .footer {
          .title {
          }
          .desc {
          }
          .has {
          }
        }
      }
      .panel-right {
        width: 500px;
        min-height: 330px;
        padding: 8px;
        display: flex;
        flex-direction: column;
        h4 {
          margin: 10px 0;
        }
        input {
          width: 100%;
        }
        textarea {
          width: 100%;
          min-height: 160px;
        }
        .header {
          display: flex;
          flex-direction: column;
          img {
            width: 33px;
            height: 33px;
          }
        }
        .hashtag {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
        .guidance {
          display: flex;
          flex-direction: column;
        }
        .radio-container {
          margin: 8px 0;
          display: flex;
          .radio {
            display: flex;
            margin-right: 16px;
            .desc {
              margin-left: 16px;
            }
            .card-radio {
              position: relative;
            }
            .card-radio::before {
              margin-top: 3px;
              position: absolute;
              content: ' ';
              display: inline-block;
              cursor: pointer;
              width: 12px;
              height: 12px;
              border: 1px solid;
              border-radius: 50%;
            }
            .card-radio-checked {
              position: relative;
            }
            .card-radio-checked::before {
              position: absolute;
              margin-top: 3px;
              content: ' ';
              display: inline-block;
              cursor: pointer;
              width: 6px;
              height: 6px;
              border: 4px solid blue;
              border-radius: 50%;
            }
            input {
              width: auto;
            }
          }
          .checkall {
            margin-right: 16px;
          }
        }
        .btns {
          margin-top: 16px;
          display: flex;
          flex-direction: column;

          .btn-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn {
              background: #ac6b5c;
              border-radius: 4px;
              color: #fff;
              padding: 4px 8px;
            }
          }
          .single {
            justify-content: center;
          }
        }
      }
      .panel-imgs {
        .btn {
          /* width: 100px; */
          line-height: 4px;
          border: 1px solid #acacac;
          border-radius: 4px;
          padding: 6px 8px 7px 8px;
          cursor: pointer;
          margin-top: 4px;
        }
        .imgs {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          max-height: 500px;
          overflow-y: auto;

          .img-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 16px;
            img {
              width: 200px;
              height: 200px;
            }
            .btn {
              width: 100px;
              line-height: 4px;
              border: 1px solid #acacac;
              border-radius: 4px;
              padding: 4px 8px 7px 8px;
              cursor: pointer;
              margin-top: 4px;
            }
          }
        }
      }
    }

    ${scrollbarStyle('.edgeless-copilot-panel')}
    .edgeless-copilot-panel:hover::-webkit-scrollbar-thumb {
      background-color: var(--affine-black-30);
    }
  `;

  private _getChain() {
    return this.edgeless.service.std.command.chain();
  }

  protected override firstUpdated(
    changedProperties: Map<PropertyKey, unknown>
  ): void {
    // 首先调用父类的 firstUpdated 方法
    super.firstUpdated(changedProperties);

    // 然后执行子类特定的逻辑
    console.log('Child firstUpdated');
    this.handleCtrlC();
  }
  override connectedCallback(): void {
    super.connectedCallback();
    this._disposables.add(on(this, 'wheel', stopPropagation));
    this._disposables.add(on(this, 'pointerdown', stopPropagation));
    this._disposables.add(on(this, 'keydown', stopPropagation));
  }
  override disconnectedCallback() {
    super.disconnectedCallback();
    this.renderRoot.removeEventListener('keydown', this.handleKeydown);
  }

  handleCtrlC() {
    this.renderRoot.addEventListener('keydown', this.handleKeydown);
  }
  handleKeydown = e => {
    console.log(e, e.target instanceof HTMLInputElement);
    if (
      !(e.target instanceof HTMLInputElement) &&
      !(e.target instanceof HTMLTextAreaElement)
    )
      return;
    if (e.ctrlKey || e.metaKey) {
      switch (e.key.toLowerCase()) {
        case 'c':
          this.handleCopy(e);
          break;
        case 'v':
          this.handlePaste(e);
          break;
      }
    }
  };

  getSelectedText(input: HTMLInputElement): string {
    const start = input.selectionStart ?? 0;
    const end = input.selectionEnd ?? 0;
    return input.value.substring(start, end);
  }

  handleCopy(e: KeyboardEvent) {
    const input = e.target as HTMLInputElement;

    e.preventDefault();
    const selectedText = this.getSelectedText(input);
    if (selectedText) {
      navigator.clipboard
        .writeText(selectedText)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }
  }

  handlePaste(e: KeyboardEvent) {
    const input = e.target as HTMLInputElement;
    e.preventDefault();
    navigator.clipboard
      .readText()
      .then(text => {
        const start = input.selectionStart ?? 0;
        const end = input.selectionEnd ?? 0;
        const currentValue = input.value;
        const newValue =
          currentValue.slice(0, start) + text + currentValue.slice(end);
        input.value = newValue;
        input.setSelectionRange(start + text.length, start + text.length);
        this.dispatchEvent(
          new CustomEvent('input', { detail: { value: newValue } })
        );
      })
      .catch(err => {
        console.error('Failed to read clipboard contents: ', err);
      });
  }
  hide() {
    // try {
    //   localStorage.setItem(
    //     this._containerId,
    //     JSON.stringify({
    //       selected: this._selectedCard,
    //     })
    //   );
    // } catch (error) {
    //   console.log(error, 'hide error');
    // }
    this.remove();
  }

  private _count = 0;

  private _data = [];

  private _renderType = '';

  private _currentData = {};

  private _selectedCard: number[] = [];

  private _imgInput = '';

  private _generatedImgList = [];

  private _containerId = '';

  get imgInput() {
    return this._imgInput;
  }

  set imgInput(value: string) {
    const oldValue = this._imgInput;
    this._imgInput = value;
    this.requestUpdate('imgInput', oldValue);
  }

  get count() {
    return this._count;
  }

  set count(value: number) {
    const oldValue = this._count;
    this._count = value;
    this.requestUpdate('count', oldValue);
  }

  private handleCount() {
    if (this.count < this._data.length - 1) {
      this.count += 1;
      this._currentData = this._data[this.count];
    }
    // try {
    //   localStorage.setItem(
    //     this._containerId,
    //     JSON.stringify({
    //       selected: this._selectedCard,
    //     })
    //   );
    // } catch (error) {}
  }

  private handleCheckAll() {
    this._selectedCard = [
      ...Array.from({ length: this._data.length }, (_, index) => index),
    ];
    this.requestUpdate();
  }

  private async handleExport() {
    let checkedData = this._data.filter((item, index) =>
      this._selectedCard.includes(index)
    );
    let blockStr = JSON.stringify(checkedData, null, 2).replace(/\\n/g, '\n');
    let container = this.edgeless.doc.getBlock(this._containerId);
    console.log(container, 'container');
    let childrens = container.model.children;
    childrens.forEach(item => {
      if (item.flavour === 'affine:code') {
        this.edgeless.doc.deleteBlock(item);
      }
    });

    this.edgeless.doc.addBlock(
      'affine:code',
      {
        text: new Y.Text(blockStr),
        language: 'Python',
        wrap: false,
        caption: '',
      },
      this._containerId
    );

    try {
      let paths = window.location.pathname.split('/');
      let docId = paths[paths?.length - 1];
      const response = await axios.post(
        '/back_api/local-services/create_excel',
        {
          data: checkedData,
          client_id: docId,
        },
        {
          responseType: 'blob',
          headers: {
            referrerPolicy: 'no-referrer', // 设置referrerPolicy为'no-referrer'
          },
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      // 创建临时 URL
      const url = window.URL.createObjectURL(blob);

      // 创建一个临时的 <a> 元素来触发下载
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'filename.xlsx'); // 设置下载的文件名
      document.body.appendChild(link);
      link.click();

      // 清理
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {}
    this.hide();
  }

  private handleSubCount() {
    if (this.count > 0) {
      this.count -= 1;
      this._currentData = this._data[this.count];
    }
    // localStorage.setItem(
    //   this._containerId,
    //   JSON.stringify({
    //     selected: this._selectedCard,
    //   })
    // );
  }

  private _onInput(e, type) {
    this._data[this.count][type] = e.target.value;
  }

  private _onImgInputChange(e) {
    this.imgInput = e.target.value;
    this.requestUpdate();
  }

  private _confirmImg(url) {
    this._data[this.count]['头图'] = url;
    this._generatedImgList = [];
    this.requestUpdate();
  }

  private _cancelGenerate() {
    this._generatedImgList = [];
    this.requestUpdate();
  }

  private async _onGenerateImg() {
    if (!this._imgInput) {
      return;
    }

    const response = await axios.get('/back_api/llm-services/critic_img', {
      params: { query: this._imgInput, if_multi: true },
      headers: {
        referrerPolicy: 'no-referrer', // 设置referrerPolicy为'no-referrer'
      },
    });
    if (typeof response.data === 'string') {
      this._generatedImgList = [response.data];
    } else if (typeof response.data === 'object') {
      this._generatedImgList = response.data;
    }

    this.requestUpdate();
  }

  private _onRadioChange() {
    if (this._selectedCard.includes(this.count)) {
      this._selectedCard = this._selectedCard.filter(
        item => item !== this.count
      );
    } else {
      this._selectedCard.push(this.count);
    }
    this.requestUpdate();
  }

  setData(data) {
    this._data = data;
    this._currentData = data?.[0];
  }

  setType(type: string) {
    this._renderType = type;
  }

  setContainerId(id: string) {
    this._containerId = id;
  }

  setSelectedCard(data: number[]) {
    this._selectedCard = data;
  }

  override render() {
    const chain = this._getChain();
    const groups = this.groups.reduce((pre, group) => {
      const filtered = group.items.filter(item =>
        item.showWhen?.(chain, 'edgeless', this.host)
      );

      if (filtered.length > 0) pre.push({ ...group, items: filtered });

      return pre;
    }, [] as AIItemGroupConfig[]);

    if (groups.every(group => group.items.length === 0)) return nothing;
    //  <img src=${quoteIcon} />
    return this._renderType === 'cards'
      ? html`
          <div class="edgeless-copilot-panel">
            <div class="panel-left">
              <img
                referrerpolicy="no-referrer"
                src=${this._currentData?.头图}
              />
              <div class="regenerate">
               <input
               .value="${this.imgInput}"
                  @input="${e => {
                    this._onImgInputChange(e);
                  }}"
               ></input>
                <button ${!this.imgInput ? `disabled` : ``}
                @click="${this._onGenerateImg}"
                >重新生成</button>
              </div>
            </div>

            <div class="panel-right">
              <div class="header">
                <h4>灵感标题</h4>
                <input
                  type=""
                  class="select-input"
                  placeholder=""
                  .value="${this._currentData?.灵感标题}"
                  @input="${e => {
                    this._onInput(e, '灵感标题');
                  }}"
                />
              </div>
              <div class="header">
                <h4>参考的笔记标题</h4>
                <input
                  type=""
                  class="select-input"
                  placeholder=""
                  .value="${this._currentData?.参考的笔记标题}"
                  @input="${e => {
                    this._onInput(e, '参考的笔记标题');
                  }}"
                />
              </div>
              <div class="guidance">
                <h4>文案思路</h4>
                <textarea
                  type=""
                  class="select-input"
                  placeholder=""
                  .value="${this._currentData?.文案思路}"
                  @input="${e => {
                    this._onInput(e, '文案思路');
                  }}"
                ></textarea>
              </div>
              <div class="hashtag">
                <h4>Hash Tag</h4>
                <input
                  class="select-input"
                  placeholder=""
                  .value="${this._currentData?.hashtags}"
                  @input="${e => {
                    this._onInput(e, 'hashtags');
                  }}"
                />
              </div>
              <div class="radio-container">
                <div class="radio">
                  <div
                    class="${
                      this._selectedCard.includes(this.count)
                        ? 'card-radio-checked'
                        : 'card-radio'
                    }"
                    @click=${this._onRadioChange}
                    value="male"
                  ></div>
                  <span class="desc">选择该卡片</span>
                </div>
                <button class="checkall" @click=${this.handleCheckAll}>选择全部</button>
                <span>已选择${this._selectedCard?.length}张灵感卡片</span>
              </div>
              <div class="btns">
                <div class="btn-group">
                  <div class="btn" @click=${this.handleSubCount}>上一篇</div>
                  <div class="count">
                    ${this.count + 1}/${this._data.length}
                  </div>
                  <div class="btn" @click=${this.handleCount}>下一篇</div>
                </div>

                <div class="btn-group single">
                  <div class="btn" @click=${this.handleExport}>全部导出</div>
                </div>

              </div>

            </div>
            <div class="panel-imgs">
            <div class="imgs">
            ${this._generatedImgList.map(
              item =>
                html`<div class="img-items">
                  <img src=${item} referrerpolicy="no-referrer" />
                  <button
                    class="btn"
                    @click=${e => {
                      this._confirmImg(item);
                    }}
                  >
                    应用此图片
                  </button>
                </div>`
            )}
            </div>
            ${
              this._generatedImgList?.length !== 0
                ? html`<div class="btn" @click=${this._cancelGenerate}>
                    取消生成
                  </div>`
                : ''
            }
            </div>
          </div>
        `
      : this._renderType === 'notes'
        ? html`
            <div class="edgeless-copilot-panel">
              <div class="panel-left">
                <img
                  referrerpolicy="no-referrer"
                  src=${this._currentData?.cover_url}
                />
              </div>

              <div class="panel-right">
                <div class="header">
                  <h4>灵感标题</h4>
                  <div>${this._currentData.title}</div>
                </div>

                <div class="hashtag">
                  <h4>作者</h4>
                  <div>${this._currentData.author}</div>
                </div>

                <div class="btns">
                  <div class="btn-group">
                    <div class="btn" @click=${this.handleSubCount}>上一篇</div>
                    <div class="count">
                      ${this.count + 1}/${this._data.length}
                    </div>
                    <div class="btn" @click=${this.handleCount}>下一篇</div>
                  </div>
                </div>
              </div>
            </div>
          `
        : this._renderType === 'docs'
          ? html` <div class="edgeless-copilot-panel">${this._data}</div> `
          : html` <div class="edgeless-copilot-panel">暂无数据</div> `;
  }

  @property({ attribute: false })
  accessor edgeless!: EdgelessRootBlockComponent;

  @property({ attribute: false })
  accessor entry: 'toolbar' | 'selection' | undefined = undefined;

  @property({ attribute: false })
  accessor groups!: AIItemGroupConfig[];

  @property({ attribute: false })
  accessor host!: EditorHost;

  @property({ attribute: false })
  accessor onClick: (() => void) | undefined = undefined;
}

declare global {
  interface HTMLElementTagNameMap {
    'edgeless-copilot-panel-custom': EdgelessCopilotPanel;
  }
}
