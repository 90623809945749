import "../../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/billing/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61Uy27bMBC85yt4KRAfaMiu7TgK0F8pKHElbSE+QFKxnaD/Xoq2a1GPKK+TQC53dnZnVsvfK7G1B2lX5PWOEI5W1+yUkqKG45O/aL+Uo4HcoZIpyVXdCNlGWI2lpOhAWH8N0oFpr0umU7LeaJ/99255RV8H9ANyV6VklSQ/oujP8dp/GuuwONFceXDpUmI1y4Fm4A4AgYNmnKMsPeRah5RMGQ7Gn/WRWFUjJ8/M3FPKigKlTw1hD1grs7i9p4ZxbHwb+x7tTSDW8vGQJCFJFN2GqGCmREmd0sP83Tnf86cWXyCN2YT7iu4Ck3A4AJaVb3SXxJUe5nEyZmERJe0H9P7LwpkswajGUgvO+QmSpWQCQkIYTq8CGKNuY+vUeJxMcXD0ZMFrx5k5dUb+ZhdHG+Oz+cZDSm9+23Z+b/ACoStm0XZo5Y2x7WOt8GzlDovso7sxvgT5R2GEF666tPRwcfjE2g125Rrq1OefXbOLhzLlnBJDl8O8SFZMmJyQun107XJ96XJSOW1QdP3UoVG82ytxyeRcMuK2mTHQ0NgRkV+ETS5GZpjk/X2IjNuBKr/0VwbJI7SqP6HVfqT3UV12n9cFv1GXucUe0eUfKjPal+IGAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var believerHeader = '_1m5swnsd';
export var believerPrice = '_1m5swnsh';
export var believerPriceCaption = '_1m5swnsi';
export var believerPriceWrapper = '_1m5swnsg';
export var believerSubtitle = '_1m5swnsf';
export var believerTitle = '_1m5swnse';
export var billingFrequency = '_1m5swns7';
export var billingHistorySkeleton = '_1m5swnsc';
export var currentPlan = '_1m5swns4';
export var currentPlanName = '_1m5swnsa';
export var history = '_1m5swns1';
export var historyContent = '_1m5swns2';
export var noInvoice = '_1m5swns9';
export var paymentMethod = '_1m5swns8';
export var planAction = '_1m5swns5';
export var planCard = '_1m5swns3';
export var planPrice = '_1m5swns6';
export var subscription = '_1m5swns0';
export var subscriptionSettingSkeleton = '_1m5swnsb';