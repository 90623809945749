import "../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/dnd/drop-indicator.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE71VYU/rIBT97q8gJi/Z9DHbzT0Vf4yhhW7EDhrKdNP436XQWqC02RbzvrVw7z3nHg7cxUt6v19tU7YCn1cAQNj9Jwg8VodnvVaJmikmOAI4q0W5V7RZVaJCIGm+JNtsVftd0kJ/vmE56yul82YnE0qJXRtWCcYVlZC+Ua5qBLjguujX1eKHDUIZLYSkhlUudDTXha+vm2zC6qrERwSyUuSv4xQ/IOOEHhBYWgIHWLMPxjc6UUii4fVSs/POiNqGrBPDekttc7E926vpJ8P560aKPScIKIl5XWGpCVtYA5WLUsiwznLuRLQsllb0dlFiwvZaoHXyx1mt1bGkCOhOGQllw4UW9ldUS8POYuStCDku81moEbgDS0/EtrXeLw7ze+/Ae3MZNfX6rhW2xIrODBxMFmtwMziZ+V8wuT0PYXvBDCpMG5ZOyNpj5tn4YnIncVu73DrcAb1/Zvu/3dHOg+Y0rQOnTN35dxWwfjCsA2NMeOnOVo1a58E7IKcITGMie7mPJse/eka1KRaOTG6poYNPZYH7FD/wKYg7BwLcAu0Vv0BmEmXsTQugMg+qzTgPLPeMO4mWR+/YeXDEOf5JMHKWX2JQdHRWXvbIjl61weOLi4JxCivJdlge7VQJuE1Nzgsn3fjojHfXPREDLd33YqSV+Oj7+gY1Rfc9qAgAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bottom = '_14u3h1ic';
export var edgeLine = '_14u3h1ie';
export var horizontal = '_14u3h1i7';
export var horizontalIndent = 'var(--_14u3h1i1)';
export var indicatorColor = 'var(--_14u3h1i2)';
export var left = '_14u3h1id';
export var lineAboveStyles = '_14u3h1i4';
export var lineBelowStyles = '_14u3h1i5';
export var localLineOffset = 'var(--_14u3h1i9)';
export var outlineStyles = '_14u3h1i6';
export var right = '_14u3h1ib';
export var terminalSize = 'var(--_14u3h1i0)';
export var top = '_14u3h1ia';
export var treeLine = '_14u3h1i3';
export var vertical = '_14u3h1i8';