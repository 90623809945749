import "../../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61VzXLbIBC+9yl06Ux8IGM7ceoqt75Ar70isZJoEGgAWUo6efeuwJJBlh23k4M9NrA/3w/LvXjZ6U23Tv58SZJCQE9Krbo02Tzjf8ZNI+hr6jaGhd+tsbx4JbmSFqT1GwQkGzZrLknHma0werduXEBN+3Ft69fev9z7kptPLrn+uOTWlcxo/oIVW8nSpKu4hSEqjvBZKuBlhRUf1odqWFMH0IVQHcH2aGtVkPnBZbbQW2I1laZQuk6TnDbcUsHfXIkCEZDumPNpvT4vO6V7dOkaZbjlSqaJBkEtP8AiQ44QxjXk/nCuRFtLD0KXyFCmrFXYzvYxKrJzRWZn9h6+69Vg42lyoPqOEFoUXALx6/XqDM/O48HaSs9iHCsGUEBG9esq6OBpBpNmBnv3gljVpInLKaCw7ucU983FnYT/Ohwb1drsjxJmSjPAZjZNn2BezuK2kEPQhEvDGfxwR4mPWJ2CiaaMtyZNHn3Kj92Z4zfoYQt1LyVBe9XmtDxB2F+BPmqx2fqqnoCdhxnYy/1EY8CvO4K7qw+F683qZpHCe0KWIjx/waFquB8/8YM7LkFDGeOyROOhAvsZg1wK19eRyEW2rvK7rNAbKsqgP06T0RMz53/3c0D1xFSUDbNHKgn/gxl7sXzmauqyn1nlAsKJpCOAmauXx5+xVNthu6R4S54icNniXB0vwwhUtXbg/7Rw840vaM2FE7ACzW1gqGDlnEdn1oZqBBE2m6bIUg6VEtie6/yiO4ODId35xSiOpJFG83p0dIBxGw9ctqzZGfuIIAeSge0A5BVVx6ciTSrOmD8aKTtVhuXKl4d6VDF2w2LV4YUjru9B7E7T5nl8qk7nQQjeGG6Cxoq4sdvu62XxAhlmFv/XDpdx3uJfh2CcCRJnKBXRlNhGnijup3l43Zjx2LxlAk8VYxeWF+tkLT4JsZmPD7d/HPZRnuqz+42H1PtfjDEj+TUKAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = 'lk5r1wa';
export var fontItem = 'lk5r1we';
export var fontItemContainer = 'lk5r1wd';
export var fontLabel = 'lk5r1wf';
export var menu = 'lk5r1w2';
export var menuTrigger = 'lk5r1w3';
export var notFound = 'lk5r1wh';
export var preViewLabelWrapper = 'lk5r1w1';
export var searchIcon = 'lk5r1wc';
export var searchInput = 'lk5r1wb';
export var selectedIcon = 'lk5r1wg';
export var settingWrapper = 'lk5r1w0';
export var shapeIndicator = 'lk5r1w9';
export var snapshot = 'lk5r1w7';
export var snapshotContainer = 'lk5r1w4';
export var snapshotLabel = 'lk5r1w8';
export var snapshotSkeleton = 'lk5r1w6';
export var snapshotTitle = 'lk5r1w5';