import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VWUW+bMBB+76+wKk1qpDqCNGkzov2QadqDAQNejUG2SdJW++87jKEGDFul7qUp57vz3Xd333mbhM0DrwP0doNQQVle6Agdgvp6gu+UqZqTlwhlnBoB4SwXmGlaqgglVGgqW3FO6ggdO5uapCkTeYQC9NhJ4kqmVGJJUtaA2b6TVo3mTNAIiUrQVnBhqS4iFAbBl/azJFdsRYfAxpNUvJIROhN5hzHJMrDHml41riUriXzBRmFzuvl9s7V5RUV1ptJklzRSteZ1xfrAY5I857JqRDrxaqzm7kLjyDrA9AwIqPcMqpokTANcwfboGnliSGlGGq6nMXSeBsvdqCr7nUUOnGW8ukSoYGlKhWPwYAxmZWt/ccokTTSrRNTi2JRiVlCjpjSRJi4tiVBZJcuo+5cTTb/fBZvhjHXOBj1I+6Agw5glOKavjMq77f5wv92F98F9uEGBclP7kRJNMIGIzvTbrZYNvf2JRmn4A8AtDG5N9kZ50j0mFUAVQAr9mM1x/QBsvxqlWdb2GzSC0O4suNDYhvg8YPbdoX6p4UgANITD4duo95Z9ZYSrJWddL685O5ijDDLGir3SycB08tJ0BzcTZNHdWXSNwmUgmKCVNQqmSFEOADtT5CmVGfL3A8o5qxVThjYK6F6sIGLDJRdJag+bDLVlwkQX8yp5dpJ79A+OITabwAL3rQJyVR5AAg8g+w4QH8HFHAgCH4LNKFx1zkd9b7m2v6T/9jlk0LWAOvxNgTNdt0//B4W1tlik9CHAOQs/fWr2TsLTrjj+veXXKuysQrscSyYmWsNdXz9K3Ssc1BotEt9wI1me9X5t+jVDRzN+p2pLeoRzILzwQSFKlLvZA7dEPUa+aYdVngNQnGbaWv3rQ2QFE7BP6NzC/ziJK1iEBUnbuHpecve0ybcmEtycfC+LJSBjt2t7CEbpHucBjJquE+Jws/p86UjxOOKMxL5dhjrFCnpJm9xkVxQ/1u7rzov7UrbTGyWFFQ6bCCz+ADLgl3t3CgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = 'c1u3lp0';
export var disable = 'c1u3lp1';
export var enableCloudButton = 'c1u3lpa';
export var settingButton = 'c1u3lpb';
export var showOnCardHover = 'c1u3lpc';
export var workspaceActiveStatus = 'c1u3lp7';
export var workspaceInfo = 'c1u3lp4';
export var workspaceInfoSlide = 'c1u3lp3';
export var workspaceInfoSlider = 'c1u3lp2';
export var workspaceInfoTooltip = 'c1u3lp8';
export var workspaceName = 'c1u3lp5';
export var workspaceStatus = 'c1u3lp6';
export var workspaceTitleContainer = 'c1u3lp9';