import type {
  BlockComponent,
  EditorHost,
  TextSelection,
} from '@blocksuite/affine/block-std';
import type { AffineAIPanelWidget } from '@blocksuite/affine/blocks';
import { isInsideEdgelessEditor } from '@blocksuite/affine/blocks';
import { type BlockModel, Slice } from '@blocksuite/affine/store';

import {
  insertFromMarkdown,
  markDownToDoc,
  markdownToSnapshot,
} from './markdown-utils';
import { getEdgelessRootFromEditor, getSelections } from './selection-utils';

const getNoteId = (blockElement: BlockComponent) => {
  let element = blockElement;
  while (element.flavour !== 'affine:note') {
    if (!element.parentComponent) {
      break;
    }
    element = element.parentComponent;
  }

  return element.model.id;
};

const setBlockSelection = (
  host: EditorHost,
  parent: BlockComponent,
  models: BlockModel[]
) => {
  const selections = models
    .map(model => model.id)
    .map(blockId => host.selection.create('block', { blockId }));

  if (isInsideEdgelessEditor(host)) {
    const surfaceElementId = getNoteId(parent);
    const surfaceSelection = host.selection.create(
      'surface',
      selections[0].blockId,
      [surfaceElementId],
      true
    );

    selections.push(surfaceSelection);
    host.selection.set(selections);
  } else {
    host.selection.setGroup('note', selections);
  }
};

export const insert = async (
  host: EditorHost,
  content: string,
  selectBlock: BlockComponent,
  below: boolean = true
) => {
  const blockParent = selectBlock.parentComponent;
  if (!blockParent) return;
  const index = blockParent.model.children.findIndex(
    model => model.id === selectBlock.model.id
  );
  const insertIndex = below ? index + 1 : index;

  const { doc } = host;
  const models = await insertFromMarkdown(
    host,
    content,
    doc,
    blockParent.model.id,
    insertIndex
  );
  await host.updateComplete;
  requestAnimationFrame(() => setBlockSelection(host, blockParent, models));
};

export const insertBelow = async (
  host: EditorHost,
  content: string,
  selectBlock: BlockComponent
) => {
  await insert(host, content, selectBlock, true);
};

export const insertAbove = async (
  host: EditorHost,
  content: string,
  selectBlock: BlockComponent
) => {
  await insert(host, content, selectBlock, false);
};

export const replace = async (
  host: EditorHost,
  content: string,
  firstBlock: BlockComponent,
  selectedModels: BlockModel[],
  textSelection?: TextSelection
) => {
  const firstBlockParent = firstBlock.parentComponent;
  if (!firstBlockParent) return;
  const firstIndex = firstBlockParent.model.children.findIndex(
    model => model.id === firstBlock.model.id
  );

  if (textSelection) {
    const { snapshot, job } = await markdownToSnapshot(content, host);
    await job.snapshotToSlice(
      snapshot,
      host.doc,
      firstBlockParent.model.id,
      firstIndex + 1
    );
  } else {
    selectedModels.forEach(model => {
      host.doc.deleteBlock(model);
    });

    const { doc } = host;
    const models = await insertFromMarkdown(
      host,
      content,
      doc,
      firstBlockParent.model.id,
      firstIndex
    );

    await host.updateComplete;
    requestAnimationFrame(() =>
      setBlockSelection(host, firstBlockParent, models)
    );
  }
};

const fetchFileFromUrl = async (url: string) => {
  // 使用fetch获取URL的内容
  const response = await fetch(url, { referrerPolicy: 'no-referrer' });
  if (!response.ok) {
    throw new Error('Network response was not ok ' + response.statusText);
  }
  // 将内容转换为Blob
  const blob = await response.blob();
  // 使用Blob和文件名创建File对象
  return new File([blob], '', { type: blob.type });
};

function readImageSize(file: File) {
  return new Promise<{ width: number; height: number }>(resolve => {
    const size = { width: 0, height: 0 };
    const img = new Image();

    img.onload = () => {
      size.width = img.width;
      size.height = img.height;
      URL.revokeObjectURL(img.src);
      resolve(size);
    };

    img.onerror = () => {
      URL.revokeObjectURL(img.src);
      resolve(size);
    };

    img.src = URL.createObjectURL(file);
  });
}

export const replaceTextWithImage = async (
  host: EditorHost,
  content: string,
  firstBlock: BlockComponent
) => {
  const firstBlockParent = firstBlock.parentComponent;
  if (!firstBlockParent) return;

  const { selectedBlocks } = getSelections(host, 'flat');

  const selectedBlock = selectedBlocks[0];
  const targetIndex = firstBlockParent.model.children.findIndex(
    model => model.id === selectedBlock.model.id
  );
  let edgelessRoot = getEdgelessRootFromEditor(host);
  edgelessRoot?.service.removeElement(selectedBlock.model.id);

  let file = await fetchFileFromUrl(content);
  let imgId = host.doc.addBlock(
    'affine:image',
    {
      size: file.size,
    },
    firstBlockParent.model.id,
    targetIndex
  );
  const sourceId = await edgelessRoot.doc.blobSync.set(file);
  const imageSize = await readImageSize(file);
  edgelessRoot.doc.withoutTransact(() => {
    edgelessRoot.service.updateElement(imgId, {
      sourceId,
      ...imageSize,
    });
  });
  await host.updateComplete;
};

export const copyTextAnswer = async (panel: AffineAIPanelWidget) => {
  const host = panel.host;
  if (!panel.answer) {
    return false;
  }
  return copyText(host, panel.answer);
};

export const copyText = async (host: EditorHost, text: string) => {
  const previewDoc = await markDownToDoc(host, text);
  const models = previewDoc
    .getBlocksByFlavour('affine:note')
    .map(b => b.model)
    .flatMap(model => model.children);
  const slice = Slice.fromModels(previewDoc, models);
  await host.std.clipboard.copySlice(slice);
  return true;
};
