import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Handle,
  Position,
  NodeResizer,
  NodeResizeControl,
  useReactFlow,
} from '@xyflow/react';
import {
  HolderOutlined,
  PlusOutlined,
  DeleteOutlined,
  InboxOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Col, message, Popconfirm, Row, Upload, type UploadProps } from 'antd';

import axios from 'axios';

import './drag.css';
import Dragger from 'antd/es/upload/Dragger';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import remarkToc from 'remark-toc';
import rehypeSlug from 'rehype-slug';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import rehypeHighlight from 'rehype-highlight';

// 记得引入必要的 CSS 文件
import 'katex/dist/katex.min.css';
import 'prismjs/themes/prism.css';

const HTMLContent = React.memo(({ content, isResizing, isInteracting }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument;
      doc.open();
      doc.write(content);
      doc.close();
    }
  }, [content]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <iframe
        ref={iframeRef}
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
      {(isResizing || isInteracting) && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent',
          }}
        />
      )}
    </div>
  );
});

const ResizableNode = ({ data, id }) => {
  const [isResizing, setIsResizing] = useState(false);
  const { updateNodeData } = useReactFlow();
  const handleEditorChange = text => {
    data.onChange(id, text);
  };

  const handleDeleteItem = () => {
    data.onDelete(id);
  };

  const props: UploadProps = {
    name: 'file',
    // showUploadList: false,
    multiple: false,
    action: '',
    maxCount: 1,
    beforeUpload: () => false,
    onChange: info => {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const multipleProps: UploadProps = {
    beforeUpload: file => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error('You can only upload image files!');
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        message.error('Image must smaller than 10MB!');
      }
      return isImage && isLt10M;
    },
    customRequest: async ({ file, onSuccess, onError }) => {
      try {
        // 转换文件为 base64
        const base64 = await convertToBase64(file);

        // 发送到服务器
        const response = await axios.post(
          '/back_api/local-services/upload_img/',
          {
            img_base64: base64,
          }
        );
        // 假设服务器返回的数据中包含图片 URL
        const imageUrl = `https://www.woyaozanzan.com/api/card/image/${response.data.image_id}`;
        data.onChange(id, { uid: file.uid, url: imageUrl });

        onSuccess();
      } catch (error) {
        console.error('Upload failed:', error);
        onError(error);
      }
    },
    onChange: info => {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove: file => {
      return new Promise((resolve, reject) => {
        data.onDeleteImg(id, file.uid);
        resolve(true);
      });
    },
  };
  // 辅助函数：将文件转换为 base64
  const convertToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  const handleFileChange = async ({ file }) => {
    if (file.type === 'text/html') {
      const reader = new FileReader();
      reader.onload = function (event) {
        data.onChange(id, event.target.result);
      };
      reader.readAsText(file);
    } else {
      const base64 = await convertToBase64(file);
      // 发送到服务器
      const response = await axios.post(
        '/back_api/local-services/upload_img/',
        {
          img_base64: base64,
        }
      );
      if (response?.data?.detail) {
        message.error(response?.data?.detail);
      }
      // 假设服务器返回的数据中包含图片 URL
      const imageUrl = `https://www.woyaozanzan.com/api/card/image/${response.data.image_id}`;
      data.onChange(id, { uid: file.uid, url: imageUrl });
    }
  };
  const [mode, setMode] = useState(0);
  const handleModeChange = () => {
    if (mode < 2) {
      setMode(mode + 1);
    } else {
      setMode(0);
    }
  };
  return (
    <>
      <NodeResizeControl
        style={{ background: 'transparent', border: 'none', zIndex: 10000 }}
        minWidth={100}
        minHeight={100}
        onResizeStart={() => setIsResizing(true)}
        onResizeEnd={() => setIsResizing(false)}
      >
        <ResizeIcon />
      </NodeResizeControl>
      {/* <NodeResizer
        minWidth={100}
        minHeight={100}
        isVisible={selected}
        lineStyle={{ border: '1px solid rgb(246, 246, 246)' }}
        handleStyle={{ backgroundColor: '#fff' }}
      /> */}
      <div
        style={{
          borderRadius: '8px',
          background: '#fff',
          border: '1px solid #ddd',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: 4,
          overflow: 'hidden',
        }}
      >
        <div className="drag-handle__custom">
          <div></div>
          <HolderOutlined />
          <Popconfirm
            title="删除"
            description="确认删除当前组件？"
            onConfirm={() => handleDeleteItem()}
            onCancel={() => {}}
            okText="确认"
            cancelText="取消"
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
        {data?.nodeType === 'text' && (
          <>
            {/* <iframe
              src="https://indify.co/widgets/live/counter/jzC6HN4RXkqTt56rJ7tR"
              // width="600"
              // height="400"
            ></iframe> */}

            <Row justify="end" style={{ margin: '4px 0' }}>
              <EyeOutlined
                onClick={handleModeChange}
                style={{ cursor: 'pointer' }}
              />
            </Row>
            <Row style={{ height: '100%' }} gutter={8}>
              <Col
                span={mode === 0 ? 12 : mode == 1 ? 24 : 0}
                style={{ borderRight: '1px solid #ddd' }}
              >
                <textarea
                  style={{ width: '100%', height: '100%' }}
                  value={data.content}
                  onChange={e => data.onChange(id, e.target.value)}
                />
              </Col>
              <Col span={mode === 0 ? 12 : mode == 1 ? 0 : 24}>
                <ReactMarkdown
                  remarkPlugins={[
                    remarkGfm,
                    remarkMath,
                    [remarkToc, { heading: 'contents' }],
                    remarkBreaks,
                  ]}
                  rehypePlugins={[
                    rehypeRaw,
                    rehypeSlug,
                    rehypeSanitize,
                    rehypeKatex,
                    rehypeHighlight,
                  ]}
                >
                  {data.content}
                </ReactMarkdown>
              </Col>
            </Row>
          </>
        )}

        {data?.nodeType === 'img_single' &&
          (data?.content?.length ? (
            <img
              style={{ width: '100%', height: '100%' }}
              src={data?.content?.[0].url}
            ></img>
          ) : (
            <Dragger
              style={{ height: '100%' }}
              {...props}
              accept=".jpeg,.jpg,.png,.webp"
              onChange={e => {
                handleFileChange(e);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">点击或将图片拖拽到此处上传</p>
              <p className="ant-upload-hint">支持单次上传图片</p>
            </Dragger>
          ))}

        {data?.nodeType === 'html' &&
          (data?.content ? (
            <HTMLContent
              content={data?.content}
              isResizing={isResizing}
              isInteracting={data?.isInteracting}
            ></HTMLContent>
          ) : (
            <Dragger
              style={{ height: '100%' }}
              {...props}
              accept=".html"
              onChange={e => {
                handleFileChange(e);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到此处上传</p>
              <p className="ant-upload-hint">支持单次上传html文件</p>
            </Dragger>
          ))}

        {data?.nodeType === 'img' && (
          <Upload
            accept=".jpeg,.jpg,.png,.webp"
            listType="picture-card"
            fileList={data?.content || []}
            {...multipleProps}
            multiple={true}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>上传</div>
            </div>
          </Upload>
        )}
      </div>
    </>
  );
};
function ResizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="#ddd"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ position: 'absolute', right: 5, bottom: 5 }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <polyline points="16 20 20 20 20 16" />
      <line x1="14" y1="14" x2="20" y2="20" />
      <polyline points="8 4 4 4 4 8" />
      <line x1="4" y1="4" x2="10" y2="10" />
    </svg>
  );
}

export default memo(ResizableNode);
