import "../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/affine-other-page-layout/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE81UTY+bMBC951dYK1VKDo5Ilmy37KV/ZcADuAHbsp1AWuW/dzAQIJuoak89Jczne/PGs5ViB+4tYr9WjJUoi9InbBdFXz7ou5HCl9OnkM5UcElYXmHbGbpfLqTFzEutEpbp6lSr4NHKcyd/YsLOYNecQ55LhTzYU3C46aKMdrLPtFiBl2fsrClkx8LqkxJ3yZODGytrsBdOHbWlWtfVtieyC0SCOWFSlWiln7n3we21SVjU9aow98Nf23OPHjKdkOayRdGZoJKF4tJj7Yg5Ko+2M/84OS/zDhlZFBV0BjLCjr5BDLMxIIRUBc31zbRst49MO0P4ulDisDftAyUewOENpkfpORjDLRbBKSwUs9JxKP2JW68aL4CGEi+wHObDXEjhsfWfRPiD7K6eYpqRXxTmHcoJzLSFnpbSChezirtRvS/QvQV0s5b72AxiyU4NjmcSgMSBk9fzvC3xl+rvuN2yv4W8SY9z87F8OedyATz615WmLG0F0bAg5MmNlYJtGlCqW+5KELoZbTek8P+ot6M1Zv2iM1ZDy5/u8+IQPODvLagxdhoei7avjiHdlTn/BHJag2EKw2t8eVn2g9TR+vuhn/e6vj8BaaWz4+wNZlBl6w444ywmSpu5/tH20JN8rnhFHr6LNgukSvt1UoHzXOfcXwxuAmwSqJCKz4BdV99rFBKYyyzdEwZEfr2YaPcK+uzFzbu/O6Ma1ykwHgJv1EcdZzHvz2MeQyP4z6F9fVbtmUTU5TeeMVhjsgYAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineLogo = 'id1as61';
export var draggableHeader = 'id1as63';
export var hideInSmallScreen = 'id1as68';
export var hideInWideScreen = 'id1as67';
export var iconButton = 'id1as66';
export var menu = 'id1as69';
export var menuItem = 'id1as6a';
export var root = 'id1as60';
export var topNav = 'id1as62';
export var topNavLink = 'id1as65';
export var topNavLinks = 'id1as64';