import type {
  Chain,
  EditorHost,
  InitCommandCtx,
} from '@blocksuite/affine/block-std';
import {
  type AffineAIPanelWidget,
  AFFINE_AI_PANEL_WIDGET,
  type AIItemGroupConfig,
  type AISubItemConfig,
  type CopilotSelectionController,
  EDGELESS_ELEMENT_TOOLBAR_WIDGET,
  type EdgelessElementToolbarWidget,
  matchFlavours,
  type AffineAIPanelWidgetConfig,
  BlocksUtils,
  EdgelessCopilotWidget,
} from '@blocksuite/affine/blocks';
import type { TemplateResult } from 'lit';
import { assertExists } from '@blocksuite/global/utils';

import {
  actionToGenerateAnswer,
  actionToHandler,
} from '../actions/doc-handler';
import {
  actionToHandler as edgelessActionToHandler,
  actionToHandlerCustom,
  getContentFromSelected,
} from '../actions/edgeless-handler';
import {
  imageFilterStyles,
  imageProcessingTypes,
  textTones,
  translateLangs,
  type CtxRecord,
} from '../actions/types';
import {
  buildCopyConfig,
  buildErrorConfig,
  buildFinishConfig,
  buildGeneratingConfig,
  buildImageResponseConfig,
  buildTextResponseConfig,
  getAIPanel,
} from '../ai-panel';
import { AIProvider } from '../provider';
import {
  getCopilotSelectedElems,
  getEdgelessRootFromEditor,
  getSelectedImagesAsBlobs,
  getSelectedModels,
  getSelectedNoteAnchor,
  getSelectedTextContent,
  getSelections,
  getTextContentFromBlockModels,
  selectAboveBlocks,
} from '../utils/selection-utils';
import {
  AIDoneIcon,
  AIImageIcon,
  AIImageIconWithAnimation,
  AIMindMapIcon,
  AIPenIcon,
  AIPenIconWithAnimation,
  AIPresentationIcon,
  AIPresentationIconWithAnimation,
  AISearchIcon,
  AIStarIconWithAnimation,
  ChatWithAIIcon,
  CommentIcon,
  ExplainIcon,
  ImproveWritingIcon,
  LanguageIcon,
  LongerIcon,
  MakeItRealIcon,
  MakeItRealIconWithAnimation,
  SelectionIcon,
  ShorterIcon,
  ToneIcon,
} from './icons';

import axios from 'axios';
import { createImageRenderer } from '../messages/wrapper';
import { getEdgelessCopilotWidget } from '../utils/edgeless';
import {
  actionToErrorResponse,
  actionToGenerating,
  actionToResponse,
  getElementToolbar,
} from '../actions/edgeless-response';
import { reportResponse } from '../utils/action-reporter';
import { EXCLUDING_COPY_ACTIONS } from '../actions/consts';
import { copyTextAnswer } from '../utils/editor-actions';
import { createTextRenderer } from '../messages/text';

export const translateSubItem: AISubItemConfig[] = translateLangs.map(lang => {
  return {
    type: lang,
    handler: actionToHandler('translate', AIStarIconWithAnimation, { lang }),
  };
});

export const toneSubItem: AISubItemConfig[] = textTones.map(tone => {
  return {
    type: tone,
    handler: actionToHandler('changeTone', AIStarIconWithAnimation, { tone }),
  };
});

export function createImageFilterSubItem(
  trackerOptions?: BlockSuitePresets.TrackerOptions
) {
  return imageFilterStyles.map(style => {
    return {
      type: style,
      handler: edgelessHandler(
        'filterImage',
        AIImageIconWithAnimation,
        {
          style,
        },
        trackerOptions
      ),
    };
  });
}

export function createImageProcessingSubItem(
  trackerOptions?: BlockSuitePresets.TrackerOptions
) {
  return imageProcessingTypes.map(type => {
    return {
      type,
      handler: edgelessHandler(
        'processImage',
        AIImageIconWithAnimation,
        {
          type,
        },
        trackerOptions
      ),
    };
  });
}

const blockActionTrackerOptions: BlockSuitePresets.TrackerOptions = {
  control: 'block-action-bar',
  where: 'ai-panel',
};

const textBlockShowWhen = (chain: Chain<InitCommandCtx>) => {
  const [_, ctx] = chain
    .getSelectedModels({
      types: ['block', 'text'],
    })
    .run();
  const { selectedModels } = ctx;
  if (!selectedModels || selectedModels.length === 0) return false;

  return selectedModels.some(model =>
    matchFlavours(model, ['affine:paragraph', 'affine:list'])
  );
};

const codeBlockShowWhen = (chain: Chain<InitCommandCtx>) => {
  const [_, ctx] = chain
    .getSelectedModels({
      types: ['block', 'text'],
    })
    .run();
  const { selectedModels } = ctx;
  if (!selectedModels || selectedModels.length > 1) return false;

  const model = selectedModels[0];
  return matchFlavours(model, ['affine:code']);
};

const imageBlockShowWhen = (chain: Chain<InitCommandCtx>) => {
  const [_, ctx] = chain
    .getSelectedModels({
      types: ['block'],
    })
    .run();
  const { selectedModels } = ctx;
  if (!selectedModels || selectedModels.length > 1) return false;

  const model = selectedModels[0];
  return matchFlavours(model, ['affine:image']);
};

const EditAIGroup: AIItemGroupConfig = {
  name: '使用AI编辑',
  items: [
    {
      name: 'Translate to',
      icon: LanguageIcon,
      showWhen: textBlockShowWhen,
      subItem: translateSubItem,
    },
    {
      name: 'Change tone to',
      icon: ToneIcon,
      showWhen: textBlockShowWhen,
      subItem: toneSubItem,
    },
    {
      name: '改进写作',
      icon: ImproveWritingIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('improveWriting', AIStarIconWithAnimation),
    },
    {
      name: '扩充文案',
      icon: LongerIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('makeLonger', AIStarIconWithAnimation),
    },
    {
      name: '精简文案',
      icon: ShorterIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('makeShorter', AIStarIconWithAnimation),
    },
    {
      name: 'AI续写',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('continueWriting', AIPenIconWithAnimation),
    },
  ],
};

const DraftAIGroup: AIItemGroupConfig = {
  name: 'draft with ai',
  items: [
    {
      name: '写一篇关于这个的文章',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('writeArticle', AIPenIconWithAnimation),
    },
    {
      name: '写一条关于这个的Twitter',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('writeTwitterPost', AIPenIconWithAnimation),
    },
    {
      name: '写一首关于这个的诗',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('writePoem', AIPenIconWithAnimation),
    },
    {
      name: '写一篇关于这个的博客',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('writeBlogPost', AIPenIconWithAnimation),
    },
    {
      name: '写一些关于这个的头脑风暴',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('brainstorm', AIPenIconWithAnimation),
    },
  ],
};

// actions that initiated from a note in edgeless mode
// 1. when running in doc mode, call requestRunInEdgeless (let affine to show toast)
// 2. when running in edgeless mode
//    a. get selected in the note and let the edgeless action to handle it
//    b. insert the result using the note shape
function edgelessHandler<T extends keyof BlockSuitePresets.AIActions>(
  id: T,
  generatingIcon: TemplateResult<1>,
  variants?: Omit<
    Parameters<BlockSuitePresets.AIActions[T]>[0],
    keyof BlockSuitePresets.AITextActionOptions
  >,
  trackerOptions?: BlockSuitePresets.TrackerOptions
) {
  return (host: EditorHost) => {
    if (host.doc.root?.id === undefined) return;
    const edgeless = (
      host.view.getWidget(
        EDGELESS_ELEMENT_TOOLBAR_WIDGET,
        host.doc.root.id
      ) as EdgelessElementToolbarWidget
    )?.edgeless;

    if (!edgeless) {
      AIProvider.slots.requestRunInEdgeless.emit({ host });
    } else {
      edgeless.tools.setEdgelessTool({ type: 'copilot' });
      const currentController = edgeless.tools.controllers[
        'copilot'
      ] as CopilotSelectionController;
      const selectedElements = edgeless.service.selection.selectedElements;
      currentController.updateDragPointsWith(selectedElements, 10);
      currentController.draggingAreaUpdated.emit(false); // do not show edgeless panel

      return edgelessActionToHandler(
        id,
        generatingIcon,
        variants,
        async () => {
          const selections = getSelections(host);
          const [markdown, attachments] = await Promise.all([
            getSelectedTextContent(host),
            getSelectedImagesAsBlobs(host),
          ]);
          // for now if there are more than one selected blocks, we will not omit the attachments
          const sendAttachments =
            selections?.selectedBlocks?.length === 1 && attachments.length > 0;
          return {
            attachments: sendAttachments ? attachments : undefined,
            content: sendAttachments ? '' : markdown,
          };
        },
        trackerOptions
      )(host);
    }
  };
}

function edgelessHandlerCustom<T extends keyof BlockSuitePresets.AIActions>(
  id: T,
  generatingIcon: TemplateResult<1>,
  variants?: Omit<
    Parameters<BlockSuitePresets.AIActions[T]>[0],
    keyof BlockSuitePresets.AITextActionOptions
  >,
  trackerOptions?: BlockSuitePresets.TrackerOptions
) {
  return (host: EditorHost) => {
    if (host.doc.root?.id === undefined) return;
    const edgeless = (
      host.view.getWidget(
        EDGELESS_ELEMENT_TOOLBAR_WIDGET,
        host.doc.root.id
      ) as EdgelessElementToolbarWidget
    )?.edgeless;

    if (!edgeless) {
      AIProvider.slots.requestRunInEdgeless.emit({ host });
    } else {
      edgeless.tools.setEdgelessTool({ type: 'copilot' });
      const currentController = edgeless.tools.controllers[
        'copilot'
      ] as CopilotSelectionController;
      const selectedElements = edgeless.service.selection.selectedElements;
      currentController.updateDragPointsWith(selectedElements, 10);
      currentController.draggingAreaUpdated.emit(false); // do not show edgeless panel

      return actionToHandlerCustom(
        id,
        generatingIcon,
        variants,
        async () => {
          const selections = getSelections(host);
          const [markdown, attachments] = await Promise.all([
            getSelectedTextContent(host),
            getSelectedImagesAsBlobs(host),
          ]);
          // for now if there are more than one selected blocks, we will not omit the attachments
          const sendAttachments =
            selections?.selectedBlocks?.length === 1 && attachments.length > 0;
          return {
            attachments: sendAttachments ? attachments : undefined,
            content: sendAttachments ? '' : markdown,
          };
        },
        trackerOptions
      )(host);
    }
  };
}

function updateAIPanelConfig<T extends keyof BlockSuitePresets.AIActions>(
  aiPanel: AffineAIPanelWidget,
  id: T,
  generatingIcon: TemplateResult<1>,
  variants?: Omit<
    Parameters<BlockSuitePresets.AIActions[T]>[0],
    keyof BlockSuitePresets.AITextActionOptions
  >,
  trackerOptions?: BlockSuitePresets.TrackerOptions
) {
  const { config, host } = aiPanel;
  assertExists(config);
  config.generateAnswer = actionToGenerateAnswer(
    id,
    variants,
    trackerOptions
  )(host);
  config.answerRenderer = createTextRenderer(host, { maxHeight: 320 });
  config.finishStateConfig = buildFinishConfig(aiPanel, id);
  config.generatingStateConfig = buildGeneratingConfig(generatingIcon);
  config.errorStateConfig = buildErrorConfig(aiPanel);
  config.copy = buildCopyConfig(aiPanel);
  config.discardCallback = () => {
    reportResponse('result:discard');
  };
}

export async function handleInlineAskGenerateAction(host: EditorHost) {
  const panel = getAIPanel(host);

  let selectedModels = getSelectedModels(host);
  let context = await getTextContentFromBlockModels(host, selectedModels);
  const generateAnswer: AffineAIPanelWidgetConfig['generateAnswer'] = async ({
    finish,
    input,
    signal,
    update,
  }) => {
    if (!AIProvider.actions.chat) return;

    // recover selection to get content from above blocks
    // assertExists(selection);
    // host.selection.set([selection]);

    // 调用 Python 后端 API
    const response = await axios.get(
      '/back_api/llm-services/critic_text',
      {
        params: { query: input, context },
      }
    );
    // 假设 API 返回的数据在 response.data 中
    const result = response.data;
    // 立即更新并完成
    update(result);
    finish('success');
  };

  assertExists(panel.config);
  updateAIPanelConfig(panel, 'translate', AIStarIconWithAnimation);

  panel.config.generateAnswer = generateAnswer;

  const { selectedBlocks: blocks } = getSelections(panel.host);
  if (!blocks || blocks.length === 0) return;
  const block = blocks.at(-1);
  assertExists(block);

  panel.toggle(block);
}

export async function handleGenerateImageAction(host: EditorHost) {
  if (host.doc.root?.id === undefined) return;
  const edgeless = (
    host.view.getWidget(
      EDGELESS_ELEMENT_TOOLBAR_WIDGET,
      host.doc.root.id
    ) as EdgelessElementToolbarWidget
  )?.edgeless;

  if (!edgeless) {
    AIProvider.slots.requestRunInEdgeless.emit({ host });
  } else {
    edgeless.tools.setEdgelessTool({ type: 'copilot' });
    const currentController = edgeless.tools.controllers[
      'copilot'
    ] as CopilotSelectionController;
    const selectedElements = edgeless.service.selection.selectedElements;
    currentController.updateDragPointsWith(selectedElements, 10);
    currentController.draggingAreaUpdated.emit(false); // do not show edgeless panel
  }

  const panel = getAIPanel(host);
  let selectedModels = getSelectedModels(host);
  let content = await getTextContentFromBlockModels(host, selectedModels);
  console.log(content, 'ccc');
  const generateAnswer: AffineAIPanelWidgetConfig['generateAnswer'] = async ({
    finish,
    input,
    signal,
    update,
  }) => {
    if (!AIProvider.actions.chat) return;

    // recover selection to get content from above blocks
    // assertExists(selection);
    // host.selection.set([selection]);

    const fullInput = `${input}`;

    // 调用 Python 后端 API
    const response = await axios.get(
      '/back_api/llm-services/critic_img',
      {
        params: { query: fullInput },
      }
    );
    // console.log(panel.childNodes());
    // let btn = panel.getElementsByClassName('ai-item-insert-below');

    // 假设 API 返回的数据在 response.data 中
    const result = response.data;
    // 立即更新并完成
    update(result);
    finish('success');
  };

  assertExists(panel.config);

  const edgelessCopilot = getEdgelessCopilotWidget(host);
  let internal: Record<string, unknown> = {};
  const selectedElements = getCopilotSelectedElems(host);
  const { selectedBlocks } = getSelections(host);
  const ctx = {
    get() {
      return {
        ...internal,
        selectedElements,
      };
    },
    set(data: Record<string, unknown>) {
      internal = data;
    },
  };

  edgelessCopilot.hideCopilotPanel();
  edgelessCopilot.lockToolbar(true);

  panel.config.answerRenderer = createImageRenderer(host, { height: 300 });
  panel.config.finishStateConfig = {
    responses: buildImageResponseConfig(panel, 'createImage'),
    actions: [],
  };

  panel.config.generateAnswer = generateAnswer;

  const elementToolbar = getElementToolbar(host);
  const isEmpty = selectedElements.length === 0;
  const isCreateImageAction = true;
  let referenceElement = null;
  let togglePanel = () => Promise.resolve(isEmpty);

  if (selectedBlocks && selectedBlocks.length !== 0) {
    referenceElement = selectedBlocks.at(-1);
  } else if (edgelessCopilot.visible && edgelessCopilot.selectionElem) {
    referenceElement = edgelessCopilot.selectionElem;
  } else if (elementToolbar.toolbarVisible) {
    referenceElement = getElementToolbar(host);
  } else if (!isEmpty) {
    const lastSelected = selectedElements.at(-1)?.id;
    assertExists(lastSelected);
    referenceElement = getSelectedNoteAnchor(host, lastSelected);
  }

  if (!referenceElement) return;

  if (isCreateImageAction) {
    togglePanel = async () => {
      if (isEmpty) return true;
      const { notes, shapes, images, edgelessTexts, embedSyncedDocs } =
        BlocksUtils.splitElements(selectedElements);
      const blocks = [
        ...notes,
        ...shapes,
        ...images,
        ...edgelessTexts,
        ...embedSyncedDocs,
      ];
      if (blocks.length === 0) return true;
      const content = await getContentFromSelected(host, blocks);
      ctx.set({
        content,
      });
      return content.length === 0;
    };
  }

  togglePanel()
    .then(isEmpty => {
      panel.toggle(referenceElement, isEmpty ? undefined : 'placeholder');
    })
    .catch(console.error);
  // const { selectedBlocks: blocks } = getSelections(panel.host);
  // if (!blocks || blocks.length === 0) return;
  // const block = blocks.at(-1);
  // assertExists(block);

  // panel.toggle(block);
}

const ReviewWIthAIGroup: AIItemGroupConfig = {
  name: 'review with ai',
  items: [
    {
      name: '按您的意见改进文案',
      icon: AIDoneIcon,
      showWhen: textBlockShowWhen,
      handler: context => {
        const aiPanel = getAIPanel(context);
        handleInlineAskGenerateAction(aiPanel.host);
      },
    },
    {
      name: '按您的意见改进图片',
      icon: AIImageIcon,
      showWhen: imageBlockShowWhen,
      handler: context => {
        const rootComponent = getEdgelessRootFromEditor(context);
        const view = rootComponent.host.view;
        const affineAIPanelWidget = view.getWidget(
          AFFINE_AI_PANEL_WIDGET,
          rootComponent.model.id
        ) as AffineAIPanelWidget;
        assertExists(affineAIPanelWidget);
        assertExists(affineAIPanelWidget.host);
        handleGenerateImageAction(affineAIPanelWidget.host);
      },
    },
    {
      name: '修正拼写',
      icon: AIDoneIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('fixSpelling', AIStarIconWithAnimation),
    },
    {
      name: '改进语法',
      icon: AIDoneIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('improveGrammar', AIStarIconWithAnimation),
    },
    {
      name: '解释图片',
      icon: AIPenIcon,
      showWhen: imageBlockShowWhen,
      handler: actionToHandler('explainImage', AIStarIconWithAnimation),
    },
    {
      name: '解释代码',
      icon: ExplainIcon,
      showWhen: codeBlockShowWhen,
      handler: actionToHandler('explainCode', AIStarIconWithAnimation),
    },
    {
      name: '检查代码错误',
      icon: ExplainIcon,
      showWhen: codeBlockShowWhen,
      handler: actionToHandler('checkCodeErrors', AIStarIconWithAnimation),
    },
    {
      name: '解释选中的区域内容',
      icon: SelectionIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('explain', AIStarIconWithAnimation),
    },
  ],
};

const GenerateWithAIGroup: AIItemGroupConfig = {
  name: 'generate with ai',
  items: [
    {
      name: '总结内容',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('summary', AIPenIconWithAnimation),
    },
    {
      name: '生成标题',
      icon: AIPenIcon,
      beta: true,
      handler: actionToHandler('createHeadings', AIPenIconWithAnimation),
      showWhen: chain => {
        const [_, ctx] = chain
          .getSelectedModels({
            types: ['block', 'text'],
          })
          .run();
        const { selectedModels } = ctx;
        if (!selectedModels || selectedModels.length === 0) return false;

        return selectedModels.every(
          model =>
            matchFlavours(model, ['affine:paragraph', 'affine:list']) &&
            !model.type.startsWith('h')
        );
      },
    },
    {
      name: '生成图片',
      icon: AIImageIcon,
      showWhen: textBlockShowWhen,
      handler: edgelessHandlerCustom('createImage', AIImageIconWithAnimation),
    },
    {
      name: '生成大纲',
      icon: AIPenIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('writeOutline', AIPenIconWithAnimation),
    },
    {
      name: '头脑风暴思维导图',
      icon: AIMindMapIcon,
      showWhen: textBlockShowWhen,
      handler: edgelessHandler('brainstormMindmap', AIPenIconWithAnimation),
    },
    {
      name: '生成 presentations',
      icon: AIPresentationIcon,
      showWhen: textBlockShowWhen,
      handler: edgelessHandler('createSlides', AIPresentationIconWithAnimation),
      beta: true,
    },
    {
      name: '变得更真实',
      icon: MakeItRealIcon,
      beta: true,
      showWhen: textBlockShowWhen,
      handler: edgelessHandler('makeItReal', MakeItRealIconWithAnimation),
    },
    {
      name: 'Find actions',
      icon: AISearchIcon,
      showWhen: textBlockShowWhen,
      handler: actionToHandler('findActions', AIStarIconWithAnimation),
      beta: true,
    },
  ],
};

const OthersAIGroup: AIItemGroupConfig = {
  name: 'Others',
  items: [
    {
      name: 'Continue with AI',
      icon: CommentIcon,
      handler: host => {
        const panel = getAIPanel(host);
        AIProvider.slots.requestOpenWithChat.emit({ host, autoSelect: true });
        panel.hide();
      },
    },
    {
      name: '打开 AI Chat',
      icon: ChatWithAIIcon,
      handler: host => {
        const panel = getAIPanel(host);
        AIProvider.slots.requestOpenWithChat.emit({ host });
        panel.hide();
      },
    },
  ],
};

export const AIItemGroups: AIItemGroupConfig[] = [
  ReviewWIthAIGroup,
  EditAIGroup,
  GenerateWithAIGroup,
  DraftAIGroup,
  OthersAIGroup,
];

export function buildAIImageItemGroups(): AIItemGroupConfig[] {
  return [
    {
      name: '使用AI编辑',
      items: [
        {
          name: '解释图片',
          icon: AIImageIcon,
          showWhen: () => true,
          handler: actionToHandler(
            'explainImage',
            AIStarIconWithAnimation,
            undefined,
            blockActionTrackerOptions
          ),
        },
      ],
    },
    {
      name: '用 Noumena-AI 完善您的作品',
      items: [
        {
          name: '生成图片',
          icon: AIImageIcon,
          showWhen: () => true,
          handler: edgelessHandler(
            'createImage',
            AIImageIconWithAnimation,
            undefined,
            blockActionTrackerOptions
          ),
        },
        {
          name: '图片处理',
          icon: AIImageIcon,
          showWhen: () => true,
          subItem: createImageProcessingSubItem(blockActionTrackerOptions),
          subItemOffset: [12, -6],
          beta: true,
        },
        {
          name: 'AI 图片过滤',
          icon: ImproveWritingIcon,
          showWhen: () => true,
          subItem: createImageFilterSubItem(blockActionTrackerOptions),
          subItemOffset: [12, -4],
          beta: true,
        },
        {
          name: '生成图片描述',
          icon: AIPenIcon,
          showWhen: () => true,
          beta: true,
          handler: actionToHandler(
            'generateCaption',
            AIStarIconWithAnimation,
            undefined,
            blockActionTrackerOptions
          ),
        },
      ],
    },
    OthersAIGroup,
  ];
}

export function buildAICodeItemGroups(): AIItemGroupConfig[] {
  return [
    {
      name: '使用AI编辑',
      items: [
        {
          name: '解释代码',
          icon: ExplainIcon,
          showWhen: () => true,
          handler: actionToHandler(
            'explainCode',
            AIStarIconWithAnimation,
            undefined,
            blockActionTrackerOptions
          ),
        },
        {
          name: '检查代码错误',
          icon: ExplainIcon,
          showWhen: () => true,
          handler: actionToHandler(
            'checkCodeErrors',
            AIStarIconWithAnimation,
            undefined,
            blockActionTrackerOptions
          ),
        },
      ],
    },
    OthersAIGroup,
  ];
}
