import "../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE8VW227bMAx971cIBQY0QBXkvszB9h/DsAfFom2tsmRIcpNu6L9PF9uRHSddigJ7iymRPDw8IjOlpZ4xNUN/7hCiTFecvCQo43DcWUMquVQJeibqAWOSZUwANnA0uFKsJOoF+wsTd7UAlhcmQetV5V1dCKwLxcRTgmbOcmDUFAmaz2af3CfhLBeYGSh1grRRYNLC2Y0iQjPDpEjQnqRPuZK1oCETmk0X+hHJiqTMvPgv5yKfQWVcHhJUMEpBeOi10g47hYzU3AQAnOO0ICKHpI2xu3u9mzYcJIUL5JkYJh5w4C92xZ8i/KDEEJxylj6RPYevRtXw0wds4VSSCQMqcpr785a+1TrQt5eK2hSKUFZrR1own3Wox2IKIXaM/zJynFk+gE5COtcsQh2HvfsliLo58RdLcsRNI5ebBlTJRGtbBFtX3MIXV8m2oQo4MewZQiiVW08Oma0bb0OslodWJbI23OJIkJDCe1WEUibyxm0bsgXeqSJ5HvHeYkj2kEkFoQ/SUiSs5/39roeM7LXktfE5jKyskAMAL0frXibhp8UP3x+wPfV8BBixvlf9QprPWNXh6IKW31Ce7X4KheQ00t+ZWhYhZxO6gxepP0B4jF/BGxx64cRMdp0aZptfjBYZbQsHGXzQE+Tp+n1hxiAuG4itSMcAt08YfUNJxpQ2livGadBvT3PzTU/i3oOTUQcV8m97Dsv4iq0UrW972zmp2oHQxVyNz+8LEc5nc2afBdbsNwzk5u0FXsZTbj2eywelTEEaVG7VWZfiDEXIbYgyuz4LgdbehEkJTx/cJEAYbexDmrR5fM9sPwsbFGvbRz8gDopUzlxr+xY0cIuknRsd+E2Q2cjCuEqBLifdnUOjqs1sdgVEBNNvzFNK4JxVmukTNRZrFu3ADuzni2AvLmYNdsLR/mq+WtlR/yOr765ie5s231biePnMFh4XPdB4B+fLx8FpuLupGZHiByuvXae9/0kdbPJ/YY+MDGdx4ohFMqyug7+/Cf6vWhuWufzNtvbJQNCrnU0/LkcYXtiP2s34H9rXvyqBugc7CwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = 'dms0irb';
export var dateCell = 'dms0ira';
export var dndCell = 'dms0ir2';
export var dragPageItemOverlay = 'dms0ir1';
export var iconCell = 'dms0ir8';
export var operationsCell = 'dms0irc';
export var root = 'dms0ir0';
export var selectionCell = 'dms0ir4';
export var tagsCell = 'dms0ir9';
export var titleCell = 'dms0ir5';
export var titleCellMain = 'dms0ir6';
export var titleCellPreview = 'dms0ir7';
export var titleIconsWrapper = 'dms0ir3';