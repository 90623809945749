import { AIStarIcon } from '@blocksuite/affine-components/icons';
import { type EditorHost } from '@blocksuite/affine/block-std';
import { WithDisposable } from '@blocksuite/affine/global/utils';
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { AIItemGroupConfig, FrameBlockModel } from '@blocksuite/blocks';
import type { EdgelessRootBlockComponent } from '@blocksuite/blocks';

import { GfxGroupLikeElementModel as SurfaceGroupLikeModel } from '@blocksuite/block-std/gfx';
import { BlockModel } from '@blocksuite/store';

import { getEdgelessCopilotWidget } from '../../utils/edgeless';
import { getBlocksInfo } from './utils.js';

import EventBus from '../../../../../eventBus';
import axios from 'axios';
import { requestConnectedFrame } from '@blocksuite/affine-shared/utils';

export function isFrameBlock(
  element: BlockModel | BlockSuite.EdgelessModel | null
): element is FrameBlockModel {
  return (
    !!element && 'flavour' in element && element.flavour === 'affine:frame'
  );
}

interface ContainerIds {
  [key: string]: string;
}
interface BlockPosition {
  [key: string]: [number, number];
}
type BlockPositions = BlockPosition[];
interface Block {
  block_type: string;
  block_content: string;
}

interface Container {
  blocks: Block[];
  detail: Record<string, any>;
  session_id?: string;
}

interface Context {
  this_container: {
    [key: string]: Container;
  };
  upstream_containers: {
    [key: string]: Container;
  };
  downstream_containers: Record<string, any>;
}

interface DataStructure {
  agent_action_name: string;
  all_block_positions: BlockPositions;
  context: Context;
  msg_type: string;
  requirement: string;
  workspace_id?: string;
  user_id?: string;
}
interface BlockInfo {
  block_type: string;
  block_content: string | Blob | { label: string; checked: boolean } | any;
}

@customElement('edgeless-copilot-toolbar-entry-rerun')
export class EdgelessCopilotToolbarEntry extends WithDisposable(LitElement) {
  static override styles = css`
    .copilot-icon-button {
      line-height: 20px;

      .label.medium {
        color: var(--affine-brand-color);
      }
    }
  `;

  private async _showCopilotPanel() {
    const selectedElements = this.edgeless.service.selection.selectedElements;
    const toBeSelected = new Set(selectedElements);
    selectedElements.forEach(element => {
      if (isFrameBlock(element)) {
        this.edgeless.service.frame
          .getElementsInFrame(element)
          .forEach(ele => toBeSelected.add(ele));
      } else if (element instanceof SurfaceGroupLikeModel) {
        element.descendants().forEach(ele => toBeSelected.add(ele));
      }
    });
    const edgelessCopilot = getEdgelessCopilotWidget(this.host);

    requestConnectedFrame(() => {
      this.edgeless.service.tool.setEdgelessTool({ type: 'default' });
    });

    let containerId = selectedElements?.[0]?.id;
    let currentBlock = selectedElements?.[0];
    let pageId = selectedElements?.[0]?.doc?.id;
    let response;
    try {
      response = await axios.get(
        `/back_api/database-services/get_container_detail?guid=${pageId}&container_id=${containerId}`
      );
    } catch (error) {
      response = {};
    }

    let bolckDetail;
    if (localStorage.getItem(containerId)) {
      bolckDetail = JSON.parse(localStorage.getItem(containerId) || '');
    } else {
      try {
        bolckDetail = JSON.parse(response?.data?.detail_content || '{}');
      } catch (error) {
        bolckDetail = {};
      }
    }

    let blocksInfo: BlockInfo[] = await getBlocksInfo(
      selectedElements?.[0]?.children,
      this.host
    );

    edgelessCopilot.hideCopilotPanel();

    let positions: BlockPositions = Object.values(this.host?.doc.blocks.value)
      .filter(item => item.flavour == 'affine:note')
      .map(item => {
        const blockPosition: [number, number] = [
          item.model.elementBound.x,
          item.model.elementBound.y,
        ];
        return { [item.id]: blockPosition };
      });

    let currentContext = {
      [containerId]: {
        blocks: blocksInfo,
        detail: bolckDetail || {},
      },
    };

    let upstreamIds = this.edgeless.service
      .getConnectors(containerId)
      .filter(item => item.target.id === containerId)
      .map(item => item.source.id as string);
    let downstreamIds =
      this.edgeless.service
        .getConnectors(containerId)
        .filter(item => item.source.id === containerId)
        .map(item => item.target.id as string) || [];

    let upstreamDict: {
      [key: string]: Container;
    } = {};
    const upstreamPromises = upstreamIds.map(async id => {
      let block = this.host.doc.getBlockById(id);
      let response;
      try {
        response = await axios.get(
          `/back_api/database-services/get_container_detail?guid=${pageId}&container_id=${id}`
        );
      } catch (error) {
        response = {};
      }
      let detail;
      if (localStorage.getItem(id)) {
        try {
          detail = JSON.parse(localStorage.getItem(id));
        } catch (error) {
          detail = localStorage.getItem(id);
        }
      } else {
        try {
          detail = JSON.parse(response?.data?.detail_content || '{}');
        } catch (error) {
          detail = {};
        }
      }
      if (block?.flavour === 'affine:embed-ai-chat') {
        let messages = JSON.parse(block?.messages);
        return {
          blocks: messages?.map(({ role, content }) => ({
            block_type: role,
            block_content: content,
          })),
          detail: detail || {},
          sessionId: block?.sessionId,
          id,
        };
      }
      const childrenInfo = await getBlocksInfo(block?.children, this.host);
      return {
        blocks: childrenInfo,
        detail: detail || {},
        id,
      };
    });

    // 等待所有 Promise 解析完成
    const upstreamInfo = await Promise.all(upstreamPromises);
    upstreamInfo.forEach(({ id, detail, blocks, sessionId }) => {
      upstreamDict[id] = {
        blocks,
        detail,
        session_id: sessionId,
      };
    });

    let downstreamDict: {
      [key: string]: Container;
    } = {};
    const downstreamPromises = downstreamIds.map(async id => {
      let block = this.host.doc.getBlockById(id);
      let response;
      try {
        response = await axios.get(
          `/back_api/database-services/get_container_detail?guid=${pageId}&container_id=${id}`
        );
      } catch (error) {
        response = {};
      }
      let detail;

      try {
        detail = JSON.parse(response?.data?.detail_content || '{}');
      } catch (error) {
        detail = {};
      }
      if (block?.flavour === 'affine:embed-ai-chat') {
        let messages = JSON.parse(block?.messages);
        return {
          blocks: messages?.map(({ role, content }) => ({
            block_type: role,
            block_content: content,
          })),
          detail: detail || {},
          sessionId: block?.sessionId,
          id,
        };
      }
      const childrenInfo = await getBlocksInfo(block?.children, this.host);
      return {
        blocks: childrenInfo,
        detail: detail || {},
        id,
      };
    });

    const downstreamInfo = await Promise.all(downstreamPromises);
    downstreamInfo.forEach(({ id, detail, blocks, sessionId }) => {
      downstreamDict[id] = {
        blocks,
        detail,
        session_id: sessionId,
      };
    });

    let container_ids: ContainerIds = {};
    try {
      let response = await axios.get(
        `/back_api/database-services/template_container/${pageId}`
      );
      container_ids = response?.data?.container_ids;
    } catch (error) {}

    let message: DataStructure = {
      agent_action_name:
        container_ids?.[containerId] || currentBlock?.actionName || '',
      all_block_positions: positions,
      context: {
        this_container: currentContext,
        upstream_containers: upstreamDict,
        downstream_containers: downstreamDict,
      },
      msg_type: container_ids?.[containerId] ? 'template' : 'critic',
      requirement: blocksInfo
        ?.filter(
          item =>
            item?.block_type === 'affine:paragraph' ||
            item?.block_type === 'affine:list'
        )
        .map(item => item.block_content.label || item.block_content)
        .join('\n'),
    };

    function getCookie(name: string) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        const poppedValue = parts.pop();
        if (poppedValue) {
          const cookieValue = poppedValue.split(';')[0];
          return cookieValue || '';
        }
      }
      return '';
    }
    if (
      container_ids?.[containerId] === 'GenerateGuideLinesWithProductAndNote'
    ) {
      const user_id = getCookie('affine_user_id');
      message['workspace_id'] = this.edgeless.doc.collection.id;
      message['user_id'] = user_id;
    }
    EventBus.emit('rerun', message);
  }

  override render() {
    return html`
      <edgeless-tool-icon-button
        aria-label="Run"
        class="copilot-icon-button"
        @click=${this._showCopilotPanel}
      >
        ${AIStarIcon} <span class="label medium">Run</span>
      </edgeless-tool-icon-button>
    `;
  }

  @property({ attribute: false })
  accessor edgeless!: EdgelessRootBlockComponent;

  @property({ attribute: false })
  accessor groups!: AIItemGroupConfig[];

  @property({ attribute: false })
  accessor host!: EditorHost;
}
