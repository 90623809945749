import "../../core/src/components/affine/setting-modal/general-setting/plans/ai/ai-plan.css.ts.vanilla.css!=!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/plans/ai/ai-plan.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61VwY7bIBC971eglSq1B1ax4012vVIv/ZAKm7GDagMFnGRb7b8XA0kNTrrZNKcIzMx7897M5OF7tv65anfLBfp9h1AlFAVVokzukRYdo2hL1GeMSdMwDth/xrXohPrycnyPFaFs0DZsJffjtSSUMt6WaOku3u4eDjCZg6FMy468lqjpwAWMv5gyBbVhgpfIIgw9H7+0RJboyaftiWoZtyyMEb1Fy5PkuUveCG7wDli7MSV6XCxeDnea/YIyLsjd77WrpRsvNiEuX3hIV2oSVCnC6VGECf7yOnzdn8DPz+Mb2BssFbNyvJ6iUTgaF0cmjFczxnmQPyI4c/bxKmezUCfpWMsxM9DbNtKGKHPC8LxIMFenMSeZJ4/X7nFv8+0YNZuxW4PJx5rW/px2daj/JsZmRRATjLEYWpLaTQrOPkVsn/5tIvRyQzTTExfD0OEOGjMbRawCfGrb89+efWc+ovILX/7FM+NIa6gFp2e69hl9ReSjneuuqU2riG+vgVvnRlZRbvIfvTlJU90mTX3DNfGe5HPJZvSj0auB27Y8ck8HrrYm6W171qZ4NSIUJi1PBs2fJ3npVcqm7GAubHH5/v9A26YuFPGs+Qk8rHApNPP0FXS2Tbdxb0JZVtAIBUFUqz+30ff3E/2KWL7i5JoKcBWpf7RK2EGweg1K2WzfRtoxFVLZv/fBgF9FbmEEj4yQh8PbH0XAur4eCAAA\"}!../../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionBlock = '_17q6gw35';
export var actionButtons = '_17q6gw36';
export var agreement = '_17q6gw39';
export var benefitGroup = '_17q6gw3b';
export var benefitItem = '_17q6gw3e';
export var benefitList = '_17q6gw3d';
export var benefitTitle = '_17q6gw3c';
export var benefits = '_17q6gw3a';
export var card = '_17q6gw30';
export var learnAIButton = '_17q6gw38 _17q6gw37';
export var purchaseButton = '_17q6gw37';
export var title = '_17q6gw34';
export var titleBlock = '_17q6gw31';
export var titleCaption1 = '_17q6gw32';
export var titleCaption2 = '_17q6gw33';