import "../../core/src/modules/create-workspace/views/dialog.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/create-workspace/views/dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VT0XKCMBB89yvy0pn2IQ5apIhfE8iB10KSSaKinf57E2IRlerYmT4xc7nbvdtdpuywWC5kRD4nhChp0KIUGdFQM4tbWLlqw3SFglqpMhLHql1NvibTMDbrxkopLDV4gIxsmX6mlJUlCqCh3rx4kELWUl+8W2gtVRodwZ52DV1rN7YDrNY2I0kUDfjmHR9Ho2q2z0hZQ3vaMCOzSLVk2P863u+/lKOGIlzruDeN8C8Vc0emqutSjHMUlYOdX8DGf4JNzmCDnn7jAfAi2DAkXo0xsRorQdFCYxwNCAval3OpOWiqGceN6e/IWfFRabkRnI65MHg2UEjBz91onPXroxmzWXy8YSQo/RHJo+q8b4zF0pO6Q4QdXrRDbtdepuip1zGo0tO93c9gzgw8msKr/a+2NIoVTj6wOwAxWCi9v1Brbq8zagRr6VGOeZqeSbD8D8YenXXoP07EyS+ZvNKnMxwEvxHYU45YblweLIQYWyubzP9yhOgQvSi0o5+ksHUIDkhI4YP3DaoT1iHEBAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudWrapper = 'az595o4';
export var avatarWrapper = 'az595o2';
export var card = 'az595o5';
export var cardDescription = 'az595o8';
export var cardText = 'az595o6';
export var cardTitle = 'az595o7';
export var cloudSvgContainer = 'az595oa';
export var cloudTips = 'az595o9';
export var header = 'az595o0';
export var subTitle = 'az595o1';
export var workspaceNameWrapper = 'az595o3';