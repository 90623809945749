import "../../core/src/components/blocksuite/block-suite-editor/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61U0W6jMBB8z1dYOp2aSOcIUkJSov5HpdM9LHgNVg3mbNPQnu7fz9gkhTStelKfEq+zM7Oz46x/5yB3aUT+LAhhwrQSnjOSS1U8Hlwlh+Kx1KprWEaeQC8pBc5Fg/T1grZa1KCfaaGk0quhSz2h5lIdaZ+RQor2sPi7WAeieE7EJfZDx/BJmdBYWKEa16VkVzfDTQuMiaakubJW1RmJo6jtJ4Cbq8pfr7MKzHIUDYK20KCkR8FKtD8ZWKDGgsX7mxIb1GAd1c2vlce8ZL7bXzB/Ftr9QJgK2ZcDo9ZKfxb19p0VO1xbeV+/D0e3ylK4DUQEOqumG7CqzcjWg75lO9WLThulM2Kxt+fFuqAcHcNETOLFcNVYasQLXoTL1yua+DD5wxFFWdmMpFE0YR9UJoE3V5qhphqY6Ex2qoZhqERux9pZwdYr8Jm9YB+kU4OFatg01ufO9N3OXIN7D28adr7BvQkrCpAUpCidwbVgTOJhbkO8jrEeatZBGa60s9Z/lW7hy03b/yA0bvsp+j5sXxkRng7kxj0ei8GV8dGkwQ8dXNxFV03bhgSMgUhGD6vR+tP5hYqGYT8kZqLi7gMVPji3ATysYmS6NuTDkrrb1RV58Uy1O7c9cSSCTUSnSTRXnY6TT//IvnHOZylyk5H9R9PB/3scb77O5H+70WBYpQUAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineDocViewport = 'qbal761';
export var docContainer = 'qbal762';
export var docEditorGap = 'qbal763';
export var docEditorRoot = 'qbal760';
export var floatBtn = 'qbal768';
export var floatBtnRe = 'qbal76a';
export var floatInput = 'qbal769';
export var pageReferenceIcon = 'qbal767';
export var titleDayTag = 'qbal765 qbal764';
export var titleTodayTag = 'qbal766 qbal764';