import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, List, Avatar, Card } from 'antd';
import axios from 'axios';
import './template-modal.css';
import {
  EdgelessEditorBlockSpecs,
  ZipTransformer,
} from '@blocksuite/affine/blocks';
import type { DocCollection, DocSnapshot } from '@blocksuite/affine/store';
import { useNavigateHelper } from '../../hooks/use-navigate-helper';
import { useServices, WorkspaceService } from '@toeverything/infra';
import { BlockStdScope } from '@blocksuite/affine/block-std';
import { Unzip } from './utils';

interface ConnectorEndpoint {
  id: string;
  position: [number, number];
  xywh?: string;
}

// 然后，我们可以为整个连接器创建一个 interface：
interface ConnectorData {
  source: ConnectorEndpoint;
  target: ConnectorEndpoint;
  stroke?: string; // 假设 stroke 是可选的颜色字符串
  strokeStyle?: string; // 假设 strokeStyle 是可选的字符串
  strokeWidth?: number; // 假设 strokeWidth 是可选的数字
  shapeStyle?: string; // 假设 shapeStyle 是可选的字符串
}

const TemplateModal = ({
  open,
  setOpen,
  docCollection,
}: {
  open: boolean;
  setOpen: (boolean: boolean) => void;
  docCollection: DocCollection;
}) => {
  const [catrgory, setCategory] = useState([]);
  const [currentTag, setCurrentTag] = useState<string>('');
  const navigateHelper = useNavigateHelper();

  useEffect(() => {
    axios
      .get(`/back_api/database-services/template_categories`)
      .then(result => {
        setCategory(result?.data);
        setCurrentTag(result?.data?.[0].template_first_class);
      })
      .catch(err => {});
  }, [open]);

  useEffect(() => {
    if (currentTag) {
      axios
        .get(`/back_api/database-services/templates_by_tag?tag=${currentTag}`)
        .then(result => {
          setTemplateList(result?.data);
        })
        .catch(err => {});
    }
  }, [currentTag]);
  const [templateList, setTemplateList] = useState([]);
  const handleListItemClick = (id: string) => {
    setCurrentTag(id);
  };

  const createConnector = (
    service,
    source: ConnectorEndpoint,
    target: ConnectorEndpoint,
    options?: {}
  ) => {
    try {
      service.addElement('connector', {
        ...options,
        strokeWidth: options?.strokeWidth ?? 6,
        shapeStyle: options?.shapeStyle ?? 'Scribbled',
        stroke: options?.stroke,
        strokeStyle: options?.strokeStyle,
        source: { id: source.id, position: source.position },
        target: { id: target.id, position: target.position },
      });
    } catch (error) {
      console.error('Error adding connector:', error);
    }
  };

  const extractConnectorsFromZip = async (blobData: Blob) => {
    const unzip = new Unzip();
    await unzip.load(blobData);

    const snapshotsBlobs: Blob[] = [];
    for (const { path, content: blob } of unzip) {
      if (path.includes('MACOSX') || path.includes('DS_Store')) {
        continue;
      }
      if (path.endsWith('.snapshot.json')) {
        snapshotsBlobs.push(blob);
        continue;
      }
    }
    let allConnector = await Promise.all(
      snapshotsBlobs.map(async blob => {
        const json = await blob.text();
        const snapshot = JSON.parse(json) as DocSnapshot;
        const containers = snapshot.blocks?.children
          ?.filter(item => item.flavour == 'affine:note')
          .reduce((result, item) => {
            result[item.id] = item.props.xywh;
            return result;
          }, {});
        let connectors = snapshot.blocks?.children
          ?.filter(item => item.flavour === 'affine:surface')
          ?.map(item =>
            Object.values(item?.props?.elements || {})?.filter(
              item => item.type === 'connector'
            )
          )
          .flat()
          .map(
            ({
              source,
              target,
              stroke,
              strokeStyle,
              strokeWidth,
              shapeStyle,
              mode,
              labelStyle,
              frontEndpointStyle,
              rearEndpointStyle,
              rough,
              roughness,
            }) => ({
              strokeWidth,
              shapeStyle,
              stroke,
              strokeStyle,
              mode,
              labelStyle,
              frontEndpointStyle,
              rearEndpointStyle,
              rough,
              roughness,
              source: {
                position: source.position,
                xywh: containers?.[source.id],
              },
              target: {
                position: target.position,
                xywh: containers?.[target.id],
              },
            })
          );
        return connectors;
      })
    );
    return allConnector?.flat();
  };
  const specs = EdgelessEditorBlockSpecs;
  const handleTemplateClick = async (id: string) => {
    let response = await axios.get(
      `/back_api/database-services/template/${id}/zipfile`,
      {
        responseType: 'blob',
      }
    );
    if (response?.data) {
      const originalConnectors = await extractConnectorsFromZip(response?.data);
      debugger;
      const newDocs = await ZipTransformer.importDocs(
        docCollection,
        response?.data
      );

      const doc = newDocs?.[0];
      let std = new BlockStdScope({
        doc: doc,
        extensions: specs,
      });
      const service = std.getService('affine:page');
      const blocks = Object.values(doc?.blocks?.value)?.map(item => item.model);

      const containers = blocks?.filter(item => item.flavour == 'affine:note');
      let containerDict: {
        [xywh: string]: string;
      } = containers?.reduce((result, item) => {
        result[item.xywh] = item.id;
        return result;
      }, {});

      doc?.transact(() => {
        originalConnectors?.forEach(item => {
          item['source']['id'] = containerDict?.[item?.source?.xywh];
          item['target']['id'] = containerDict?.[item?.target?.xywh];
          const { source, target, ...rest } = item;
          createConnector(service, source, target, {
            ...rest,
          });
        });
      });
      doc?.transact(() => {
        containers.forEach(item => {
          item.children?.forEach(child => {
            if (child.flavour === 'affine:code') {
              try {
                let string = child.text?.toString();
                let jsonString = `${string}`?.replace(/'/g, '"');
                let actionName = JSON.parse(jsonString)?.agent_action_name;
                console.log(actionName, 'actionName');
                containerDict[item?.id] = actionName || '';
                doc?.updateBlock(item, {
                  actionName,
                  msg_type: 'template',
                });
                doc?.deleteBlock(child);
              } catch (error) {}
            }
          });
          doc?.transact(() => {
            doc?.updateBlock(item, {
              msg_type: 'template',
            });
          });
        });
      });

      axios.post('/back_api/database-services/record_template_containers', {
        guid: doc?.id,
        container_ids: containerDict,
      });
      setOpen(false);
      doc?.transact(() => {
        navigateHelper.jumpToPage(doc?.collection.id, doc?.id, 'replace');
      });
    }
  };

  return (
    <Modal
      width="80%"
      open={open}
      title="模板选择"
      okText="确认"
      cancelText="取消"
      onCancel={() => {
        setOpen(false);
      }}
      onClose={() => {
        setOpen(false);
      }}
      wrapClassName="template-modal"
    >
      <Row gutter={16}>
        <Col span={4}>
          <List
            itemLayout="horizontal"
            dataSource={catrgory}
            renderItem={(item, index) => (
              <List.Item
                onClick={() => {
                  handleListItemClick(item?.template_first_class);
                }}
                className={
                  currentTag === item?.template_first_class ? 'active' : ''
                }
              >
                <List.Item.Meta title={item?.template_first_class} />
              </List.Item>
            )}
          />
        </Col>
        <Col span={20}>
          <List
            grid={{
              gutter: 16,
              column: 5,
            }}
            dataSource={templateList}
            renderItem={item => (
              <List.Item>
                <Card
                  title={item?.template_name}
                  onClick={() => {
                    handleTemplateClick(item?.template_id);
                  }}
                >
                  <img
                    src={`/back_api/database-services/template/${item?.template_id}/imgfile`}
                  ></img>
                  <div>{item?.description}</div>
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default TemplateModal;
