import { DocDisplayMetaService } from '@affine/core/modules/doc-display-meta';
import { WorkspacePropertiesAdapter } from '@affine/core/modules/properties';
import { I18n } from '@affine/i18n';
import { track } from '@affine/track';
import type { EditorHost } from '@blocksuite/affine/block-std';
import type { AffineInlineEditor } from '@blocksuite/affine/blocks';
import { LinkedWidgetUtils } from '@blocksuite/affine/blocks';
import type { DocMeta } from '@blocksuite/affine/store';
import { type FrameworkProvider, WorkspaceService } from '@toeverything/infra';
import type { TemplateResult } from 'lit';
import { NewDocIcon } from '@blocksuite/affine-components/icons';
import { html } from 'lit';

const DEFAULT_DOC_NAME = 'Untitled';
const DISPLAY_NAME_LENGTH = 8;

type LinkedMenuItem = {
  key: string;
  name: string;
  icon: TemplateResult<1>;
  // suffix?: TemplateResult<1>;
  // disabled?: boolean;
  action: () => Promise<void> | void;
};

type LinkedMenuGroup = {
  name: string;
  items: LinkedMenuItem[];
  styles?: string;
  // maximum quantity displayed by default
  maxDisplay?: number;
  // copywriting when display quantity exceeds
  overflowText?: string;
};

function createWorkFlowItemMenuGroup(
  query: string,
  abort: () => void,
  editorHost: EditorHost,
  inlineEditor: AffineInlineEditor
): LinkedMenuGroup {
  let originItems = ['灵感卡片workflow', '筛选达人workflow'].map(item => ({
    key: 'create',
    name: item,
    icon: html`<svg
      t="1730269649070"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4359"
      width="128"
      height="128"
    >
      <path
        d="M384 640a42.666667 42.666667 0 1 0 42.666667 42.666667 42.666667 42.666667 0 0 0-42.666667-42.666667z m-298.666667-42.666667a42.666667 42.666667 0 0 0-42.666666 42.666667v85.333333a42.666667 42.666667 0 0 0 85.333333 0v-85.333333a42.666667 42.666667 0 0 0-42.666667-42.666667z m853.333334 0a42.666667 42.666667 0 0 0-42.666667 42.666667v85.333333a42.666667 42.666667 0 0 0 85.333333 0v-85.333333a42.666667 42.666667 0 0 0-42.666666-42.666667z m-213.333334-298.666666h-170.666666V244.053333A85.333333 85.333333 0 0 0 597.333333 170.666667a85.333333 85.333333 0 0 0-170.666666 0 85.333333 85.333333 0 0 0 42.666666 73.386666V298.666667H298.666667a128 128 0 0 0-128 128v384a128 128 0 0 0 128 128h426.666666a128 128 0 0 0 128-128v-384a128 128 0 0 0-128-128z m-139.946666 85.333333l-21.333334 85.333333h-104.106666l-21.333334-85.333333zM768 810.666667a42.666667 42.666667 0 0 1-42.666667 42.666666H298.666667a42.666667 42.666667 0 0 1-42.666667-42.666666v-384a42.666667 42.666667 0 0 1 42.666667-42.666667h52.053333L384 522.24a42.666667 42.666667 0 0 0 42.666667 32.426667h170.666666a42.666667 42.666667 0 0 0 42.666667-32.426667L673.28 384H725.333333a42.666667 42.666667 0 0 1 42.666667 42.666667z m-128-170.666667a42.666667 42.666667 0 1 0 42.666667 42.666667 42.666667 42.666667 0 0 0-42.666667-42.666667z"
        p-id="4360"
        fill="#77757d"
      ></path>
    </svg>`,

    action: () => {
      abort();
      if (!inlineEditor) return;
      const inlineRange = inlineEditor.getInlineRange();
      if (!inlineRange) return;
      inlineEditor.insertText(inlineRange, `@${item}  `);
      inlineEditor.setInlineRange({
        index: inlineRange.index + item?.length + 2,
        length: 0,
      });
    },
  }));
  return {
    name: 'Workflows',
    items: originItems.filter(({ name }) => isFuzzyMatch(name, query)),
  };
}

function createNewItemMenuGroup(
  query: string,
  abort: () => void,
  editorHost: EditorHost,
  inlineEditor: AffineInlineEditor
): LinkedMenuGroup {
  let originItems = [
    '搜索花字头图',
    '图片搜索',
    '图片生成',
    '上下游词',
    '打标',
    '调研报告生成',
    '灵感卡片生成',
    '多维表格',
    '小红书笔记搜索',
    '小红书note_id搜索',
    '小红书用户搜索',
  ].map(item => ({
    key: 'create',
    name: item,
    icon: html`<svg
      t="1730269649070"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4359"
      width="128"
      height="128"
    >
      <path
        d="M384 640a42.666667 42.666667 0 1 0 42.666667 42.666667 42.666667 42.666667 0 0 0-42.666667-42.666667z m-298.666667-42.666667a42.666667 42.666667 0 0 0-42.666666 42.666667v85.333333a42.666667 42.666667 0 0 0 85.333333 0v-85.333333a42.666667 42.666667 0 0 0-42.666667-42.666667z m853.333334 0a42.666667 42.666667 0 0 0-42.666667 42.666667v85.333333a42.666667 42.666667 0 0 0 85.333333 0v-85.333333a42.666667 42.666667 0 0 0-42.666666-42.666667z m-213.333334-298.666666h-170.666666V244.053333A85.333333 85.333333 0 0 0 597.333333 170.666667a85.333333 85.333333 0 0 0-170.666666 0 85.333333 85.333333 0 0 0 42.666666 73.386666V298.666667H298.666667a128 128 0 0 0-128 128v384a128 128 0 0 0 128 128h426.666666a128 128 0 0 0 128-128v-384a128 128 0 0 0-128-128z m-139.946666 85.333333l-21.333334 85.333333h-104.106666l-21.333334-85.333333zM768 810.666667a42.666667 42.666667 0 0 1-42.666667 42.666666H298.666667a42.666667 42.666667 0 0 1-42.666667-42.666666v-384a42.666667 42.666667 0 0 1 42.666667-42.666667h52.053333L384 522.24a42.666667 42.666667 0 0 0 42.666667 32.426667h170.666666a42.666667 42.666667 0 0 0 42.666667-32.426667L673.28 384H725.333333a42.666667 42.666667 0 0 1 42.666667 42.666667z m-128-170.666667a42.666667 42.666667 0 1 0 42.666667 42.666667 42.666667 42.666667 0 0 0-42.666667-42.666667z"
        p-id="4360"
        fill="#77757d"
      ></path>
    </svg>`,

    action: () => {
      abort();
      if (!inlineEditor) return;
      const inlineRange = inlineEditor.getInlineRange();
      if (!inlineRange) return;
      inlineEditor.insertText(inlineRange, `@${item}  `);
      inlineEditor.setInlineRange({
        index: inlineRange.index + item?.length + 2,
        length: 0,
      });
    },
  }));
  return {
    name: 'Agents',
    items: originItems.filter(({ name }) => isFuzzyMatch(name, query)),
  };
}
// TODO: fix the type
export function createLinkedWidgetConfig(
  framework: FrameworkProvider
): Partial<Record<string, unknown>> {
  return {
    getMenus: (
      query: string,
      abort: () => void,
      editorHost: EditorHost,
      inlineEditor: AffineInlineEditor
    ) => {
      const currentWorkspace = framework.get(WorkspaceService).workspace;
      const rawMetas = currentWorkspace.docCollection.meta.docMetas;
      const adapter = framework.get(WorkspacePropertiesAdapter);
      const isJournal = (d: DocMeta) =>
        !!adapter.getJournalPageDateString(d.id);

      const docDisplayMetaService = framework.get(DocDisplayMetaService);
      const docMetas = rawMetas
        .filter(meta => {
          if (isJournal(meta) && !meta.updatedDate) {
            return false;
          }
          return !meta.trash;
        })
        .map(meta => {
          const title = docDisplayMetaService.title$(meta.id).value;
          return {
            ...meta,
            title: typeof title === 'string' ? title : I18n[title.key](),
          };
        })
        .filter(({ title }) => isFuzzyMatch(title, query));

      // TODO need i18n if BlockSuite supported
      const MAX_DOCS = 6;
      return Promise.resolve([
        createWorkFlowItemMenuGroup(query, abort, editorHost, inlineEditor),
        createNewItemMenuGroup(query, abort, editorHost, inlineEditor),
        {
          name: 'Link to Doc',
          items: docMetas.map(doc => ({
            key: doc.id,
            name: doc.title,
            icon: docDisplayMetaService
              .icon$(doc.id, {
                type: 'lit',
                reference: true,
              })
              .value(),
            action: () => {
              abort();
              LinkedWidgetUtils.insertLinkedNode({
                inlineEditor,
                docId: doc.id,
              });
              track.doc.editor.atMenu.linkDoc();
            },
          })),
          maxDisplay: MAX_DOCS,
          overflowText: `${docMetas.length - MAX_DOCS} more docs`,
        },
        LinkedWidgetUtils.createNewDocMenuGroup(
          query,
          abort,
          editorHost,
          inlineEditor
        ),
      ]);
    },
  };
}

/**
 * Checks if the name is a fuzzy match of the query.
 *
 * @example
 * ```ts
 * const name = 'John Smith';
 * const query = 'js';
 * const isMatch = isFuzzyMatch(name, query);
 * // isMatch: true
 * ```
 */
function isFuzzyMatch(name: string, query: string) {
  const pureName = name
    .trim()
    .toLowerCase()
    .split('')
    .filter(char => char !== ' ')
    .join('');

  const regex = new RegExp(
    query
      .split('')
      .filter(char => char !== ' ')
      .map(item => `${escapeRegExp(item)}.*`)
      .join(''),
    'i'
  );
  return regex.test(pureName);
}

function escapeRegExp(input: string) {
  // escape regex characters in the input string to prevent regex format errors
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
